import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'web-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent {
  @Input()
  items?: Partial<{ id: string; title: string; subtitle: string }>[] =
    undefined;
  @Input()
  type: 'donut' | 'default' = 'default';

  @Output()
  clicked = new EventEmitter();

  onClick(item: Partial<{ id: string; title: string; subtitle: string }>) {
    if (this.clicked) {
      this.clicked.emit();
    }
  }
}
