import * as moment from 'moment';

import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Category, Fund, MacroCategory } from '@metodics/api-client';
import { getDate } from '@metodics/common';
import { MacroCategoryListItem } from '../macro-category-list/macro-category-list.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'web-fund-grid',
  templateUrl: './fund-grid.component.html',
  styleUrls: ['./fund-grid.component.scss'],
})
export class FundGridComponent {
  @Input()
  macroCategory?: Partial<MacroCategoryListItem> = undefined;
  @Input()
  category?: Partial<Category> = undefined;
  @Input()
  funds?: (Fund & { selected?: boolean })[] = undefined;
  @Input()
  showSelect = false;
  @Input()
  canSelect = false;
  @Input()
  expandible = false;
  @Input()
  expanded = true;
  date = getDate(moment().toISOString(), 'YYYY/MM/DD');

  @Output()
  clicked = new EventEmitter<Fund>();

  @Output()
  selected = new EventEmitter<string>();

  @Output()
  addedToCompare = new EventEmitter<Fund>();

  @Output()
  panelExpanded = new EventEmitter<boolean>();

  searchText = '';

  searchFormControl = new FormControl('');

  filteredFunds?: (Fund & { selected?: boolean })[] = undefined;

  onClick(fund: Fund) {
    if (this.clicked) {
      this.clicked.emit(fund);
    }
  }
  selectFund(fund: Fund) {
    if (this.funds) {
      const fundIndex = this.funds.findIndex((f) => f.id === fund.id);
      this.funds.splice(fundIndex, 1, {
        ...this.funds[fundIndex],
        selected: !this.funds[fundIndex].selected,
      });
    }
  }
  addFundToWallet(fund: Fund) {
    this.selected.emit(fund.id);
  }
  toggleExpand() {
    this.expanded = !this.expanded;
    if (this.panelExpanded) {
      this.panelExpanded.emit(this.expanded);
    }
  }
  addFundToCompare(fund: Fund) {
    if (this.addedToCompare) {
      this.addedToCompare.emit(fund);
    }
  }
  searchChanged(value: string) {
    this.searchText = value;
  }
  search() {
    this.filteredFunds = this.funds?.filter((c) =>
      c.name.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  cancelSearch() {
    this.filteredFunds = this.funds;
    this.searchText = '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filteredFunds = this.funds;
  }
}
