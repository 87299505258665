import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormatter',
})
export class NumberFormatter implements PipeTransform {
  transform(value: number | string | undefined): string {
    if (value) {
      if (typeof value == 'string') value = parseFloat(value);
      return value.toLocaleString('en-US').replace(/,/g, '.');
    }
    return '';
  }
}
