import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  Fund,
  MacroCategory,
  Wallet,
  WalletCategory,
  WalletFundDto,
} from '@metodics/api-client';
import { sort } from '@metodics/common';

import { MacroCategoryListItem } from '../macro-category-list/macro-category-list.component';

@Component({
  selector: 'web-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent {
  @Input()
  isModal = false;
  @Input()
  canCreate = false;
  @Input()
  canDeleteWallet = false;
  @Input()
  canDeleteFund = false;
  @Input()
  macroCategories: MacroCategoryListItem[] | undefined = undefined;
  @Input()
  canEditName = false;

  @Input()
  month = '';

  _wallet: Wallet | undefined = undefined;
  @Input()
  public set wallet(v: Wallet | undefined) {
    if (v) {
      const funds = [...(v?.funds || [])].sort((f1, f2) => {
        let res = sort(
          f1.category.macroCategory,
          f2.category.macroCategory,
          'name'
        );
        if (res === 0) {
          res = sort(f1.category, f2.category, 'name');
        }
        return res;
      });
      v = { ...v, funds };
    }
    this._wallet = v;
    this.nameFormControl.setValue(v?.name || '');
  }

  public get wallet(): Wallet | undefined {
    return this._wallet;
  }

  @Input()
  simpleView = false;
  @Input()
  showBorder = true;

  @Output()
  create = new EventEmitter();
  @Output()
  deleteWallet = new EventEmitter();
  @Output()
  deleteFund = new EventEmitter<WalletFundDto>();
  @Output()
  closePopup = new EventEmitter();
  @Output()
  showDetails = new EventEmitter();
  @Output()
  changeName = new EventEmitter<string>();

  nameFormControl = new FormControl('');
  editing = false;

  canSave() {
    return this.wallet?.funds?.length;
  }
  onCreate() {
    if (this.canSave()) {
      if (this.create) {
        this.create.emit();
      }
    }
  }

  onDelete() {
    if (this.deleteWallet) {
      this.deleteWallet.emit();
    }
  }
  onDeleteFund(fund: WalletFundDto) {
    if (this.deleteFund) {
      this.deleteFund.emit(fund);
    }
  }

  onClose() {
    if (this.closePopup) {
      this.closePopup.emit();
    }
  }

  onShowDetails() {
    if (this.showDetails) {
      this.showDetails.emit();
    }
  }

  getWalletMacroCategories() {
    const macroCategories: MacroCategory[] = [];
    this.wallet?.funds.forEach((f) => {
      if (!macroCategories.find((mc) => mc.id === f.category.macroCategoryId)) {
        macroCategories.push(f.category.macroCategory);
      }
    });
    return macroCategories;
  }

  getWalletCategoriesByMC(macroCategoryId: string) {
    const categories: WalletCategory[] = [];
    this.wallet?.funds
      .filter((f) => f.category.macroCategoryId === macroCategoryId)
      .forEach((f) => {
        if (!categories.find((c) => c.id === f.categoryId)) {
          categories.push(f.category);
        }
      });
    return categories;
  }

  getWalletCategories() {
    const categories: WalletCategory[] = [];
    this.wallet?.funds.forEach((f) => {
      if (!categories.find((c) => c.id === f.categoryId)) {
        categories.push(f.category);
      }
    });
    return categories;
  }

  getCategoryFunds(Category: WalletCategory) {
    return this.wallet?.funds.filter((f) => f.categoryId === Category.id);
  }

  confirmName() {
    this.editing = false;
    if (this.changeName) {
      this.changeName.emit(this.nameFormControl.value || '');
    }
  }
  undoName() {
    this.nameFormControl.setValue(this.wallet?.name || '');
    this.editing = false;
  }
}
