import { OidcSecurityService } from 'angular-auth-oidc-client';
/* eslint-disable no-console */
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  first,
  switchMap,
  throwError,
} from 'rxjs';

import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '@metodics/ui-kit';

import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private authService: AuthService, //private authfacade: AuthFacade
    private router: Router,
    private toastService: ToastService,
    private oidcSecurityService: OidcSecurityService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return this.authService.accessToken$.pipe(
      first(),
      switchMap((token) => {
        if (
          !token ||
          request.url.indexOf('/api/') < 0 ||
          request.url.indexOf(
            '/realms/metodics-realm/protocol/openid-connect/token'
          ) !== -1
        ) {
          return next.handle(request).pipe(
            catchError((error) => {
              if (
                error instanceof HttpErrorResponse &&
                (!request.url.includes('/api/user/current-user') ||
                  error.error?.error !== 'DEVICES_LIMIT_EXCEEDED') &&
                error.status === 401
              ) {
                console.log(
                  'Auth interceptor - intercepting 401 error on ' + request.url
                );
                return this.handle401Error(request, next, error);
              }
              return throwError(() => error);
            })
          );
        }

        request = this.addTokenHeader(request, token);

        return next.handle(request).pipe(
          catchError((error) => {
            if (
              error instanceof HttpErrorResponse &&
              (!request.url.includes('/api/user/current-user') ||
                error.error?.error !== 'DEVICES_LIMIT_EXCEEDED') &&
              error.status === 401
            ) {
              console.log(
                'Auth interceptor - intercepting 401 error on ' + request.url
              );
              return this.handle401Error(request, next, error);
            }
            return throwError(() => error);
          })
        );
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    requestError: HttpErrorResponse
  ) {
    return this.oidcSecurityService.forceRefreshSession().pipe(
      switchMap((result) => {
        if (!result.isAuthenticated) {
          throw new Error('Impossibile ripristinare la sessione. ');
        }
        return next.handle(this.addTokenHeader(request, result.accessToken));
      }),
      catchError((err) => {
        this.toastService.errorFeedback(
          'La sessione è scaduta, sarai rediretto alla pagina di login.'
        );
        this.authService.login();
        //throw requestError;
        return EMPTY;
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    /* for Spring Boot back-end */
    // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });

    /* for Node.js Express back-end */
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + token),
    });
  }
}
