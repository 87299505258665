import { CommonModule } from '@angular/common';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';

import { ApiClientConfig } from './api-client-config';
import { CategoryApiClient } from './services/category.api-client';
import { FundApiClient } from './services/fund.api-client';
import { MacroCategoryApiClient } from './services/macro-category.api-client';
import { PaymentApiClient } from './services/payment.api-client';
import { UserDeviceApiClient } from './services/user-device.api-client';
import { UserSubscriptionApiClient } from './services/user-subscription.api-client';
import { UserApiClient } from './services/user.api-client';
import { WalletApiClient } from './services/wallet.api-client';

@NgModule({
  imports: [CommonModule],
})
export class ApiClientModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ApiClientModule
  ) {
    if (parentModule) {
      throw new Error(
        'ApiClientModule is already loaded. Import only in AppModule'
      );
    }
  }

  static forRoot(
    config: ApiClientConfig
  ): ModuleWithProviders<ApiClientModule> {
    return {
      ngModule: ApiClientModule,
      providers: [
        {
          provide: ApiClientConfig,
          useValue: config,
        },
        FundApiClient,
        CategoryApiClient,
        UserApiClient,
        WalletApiClient,
        UserDeviceApiClient,
        PaymentApiClient,
        UserSubscriptionApiClient,
        MacroCategoryApiClient,
      ],
    };
  }
}
