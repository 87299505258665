import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'web-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input()
  placeholder = '';
  @Input()
  withChevron = false;
  @Input()
  withChevronAnimation = false;
  @Input()
  styleType: 'primary' | 'primary-inverted' = 'primary-inverted';
  @Input()
  formControl?: FormControl = undefined;
  @Input()
  dark = false;
  @Input()
  type = 'text';
  @Input()
  maxlength: number | null = null;

  @Output()
  clicked = new EventEmitter<string>();

  @Output()
  textValueChange = new EventEmitter<string>();

  @Input()
  textValue: string | null = null;

  internalFormControl = new FormControl('', [
    Validators.required,
    //Validators.minLength(8),
    //Validators.pattern(new RegExp('^[0-9]+$')),
  ]);

  onClick() {
    if (this.formControl) {
      if (this.formControl?.valid && this.clicked) {
        this.clicked.emit(this.formControl.value || '');
      }
    } else if (this.internalFormControl.valid && this.clicked) {
      this.clicked.emit(this.internalFormControl.value || '');
      this.internalFormControl.reset();
    }
  }
  keyPress(event: KeyboardEvent) {
    //console.log('keypress', event);
    if (event.key.toLowerCase() === 'enter') {
      this.onClick();
    }
  }
  onChangeEvent(event: any) {
    if (this.textValueChange) {
      this.textValueChange.emit(event.target.value);
    }
  }
}
