import {
  Category,
  Fund,
  FundTotals,
  MacroCategory,
  User,
  UserDevice,
  UserSubscription,
  Wallet,
} from '@metodics/api-client';
import { createAction, props } from '@ngrx/store';

export const loadMacroCategoriesAction = createAction(`Load Macro Categories`);

export const loadMacroCategoriesSuccessAction = createAction(
  `Load macro macroCategories success`,
  props<{
    macroCategories: MacroCategory[];
  }>()
);

export const loadMacroCategoriesFailureAction = createAction(
  `Load macro macroCategories failure`,
  props<{ error: string }>()
);

export const loadFundTotalsAction = createAction(`Load fund totals`);

export const loadFundTotalsSuccessAction = createAction(
  `Load fund totals success`,
  props<{
    fundTotals: FundTotals;
  }>()
);

export const loadFundTotalsFailureAction = createAction(
  `Load fund totals failure`,
  props<{ error: string }>()
);

export const loadCurrentUserAction = createAction(`Load current user`);

export const loadCurrentUserSuccessAction = createAction(
  `Load current user success`,
  props<{
    currentUser: User;
  }>()
);

export const loadCurrentUserFailureAction = createAction(
  `Load current user failure`,
  props<{ error: string }>()
);

export const selectMacroCategoryIdAction = createAction(
  `Select macro category`,
  props<{
    macroCategoryId: string;
  }>()
);

export const loadCategoriesBySelectedMacroCategoryIdSuccessAction =
  createAction(
    `Load categories by selected macro category success`,
    props<{
      categories: Category[];
    }>()
  );

export const loadCategoriesBySelectedMacroCategoryIdFailureAction =
  createAction(
    `Load categories by selected macro category failure`,
    props<{ error: string }>()
  );

export const selectCategoryIdAction = createAction(
  `Select category `,
  props<{
    categoryId: string;
  }>()
);

export const loadFundsBySelectedCategorySuccessAction = createAction(
  `Load category funds success`,
  props<{
    funds: Fund[];
  }>()
);

export const loadFundsBySelectedCategoryFailureAction = createAction(
  `Load category funds failure`,
  props<{ error: string }>()
);

export const searchAndAddFundToCompareListAction = createAction(
  `Search and add fund to compare list`,
  props<{
    isin: string;
    navigateToCompare: boolean;
  }>()
);

export const removeFundFromCompareListAction = createAction(
  `Remove fund from compare list`,
  props<{
    fundId: string;
  }>()
);

export const loadFundSuccessAction = createAction(
  `Load fund success`,
  props<{
    fund: Fund;
  }>()
);

export const loadFundFailureAction = createAction(
  `Load fund failure`,
  props<{ error: string }>()
);

export const addFundToCompareListAction = createAction(
  `Add fund to compare list`,
  props<{
    fund: Fund;
    navigateToCompare: boolean;
  }>()
);

export const updateComparedFundModeAction = createAction(
  `Update Compared Fund Visualition Mode`,
  props<{
    fundId: string;
    mode: 'FUND_DETAILS' | 'CATEGORY_REPORT';
  }>()
);

export const updateComparedFundCategoryReportPanelExpandedStatusAction =
  createAction(
    `Category Report Expnded Status`,
    props<{
      fundId: string;
      isExpanded: boolean;
    }>()
  );

export const updateComparedFundDetailPanelExpandedStatusAction = createAction(
  `Category Report Expnded Status`,
  props<{
    fundId: string;
    isExpanded: boolean;
  }>()
);

export const loadCategoryAction = createAction(
  `Load category`,
  props<{
    id: string;
  }>()
);

export const loadCategorySuccessAction = createAction(
  `Load category success`,
  props<{
    category: Category;
  }>()
);

export const loadCategoryFailureAction = createAction(
  `Load category failure`,
  props<{ error: string }>()
);

export const addFundToDraftWalletAction = createAction(
  `Add Fund to Draft Wallet`,
  props<{
    fundId: string;
  }>()
);

export const removeFundFromDraftWalletAction = createAction(
  `Remove Fund From Draft Wallet`,
  props<{
    fundId: string;
  }>()
);

export const createWalletAction = createAction(`Create wallet`);

export const createWalletSuccessAction = createAction(
  `Create wallet success`,
  props<{
    wallet: Wallet;
  }>()
);

export const createWalletFailureAction = createAction(
  `Create wallet failure`,
  props<{ error: string }>()
);

export const selectYearAndMonthAction = createAction(
  `Select current year and month context for performance data`,
  props<{
    year: number;
    month: number;
  }>()
);

export const loadCurrentUserWalletsAction = createAction(
  `Load logged user wallets`
);

export const loadLoggedUserWalletsSuccessAction = createAction(
  `Load logged user wallets success`,
  props<{
    wallets: Wallet[];
  }>()
);

export const loadLoggedUserWalletsFailureAction = createAction(
  `Load logged user wallets failure`,
  props<{ error: string }>()
);

export const deleteWalletAction = createAction(
  `Delete wallet`,
  props<{ id: string }>()
);

export const deleteWalletSuccessAction = createAction(
  `Delete wallet success`,
  props<{ id: string }>()
);

export const deleteWalletFailureAction = createAction(
  `Delete wallet failure`,
  props<{ error: string }>()
);

export const removeFundFromWalletAction = createAction(
  `Remove Fund From Wallet`,
  props<{
    walletFundId: string;
  }>()
);

export const removeFundFromWalletSuccessAction = createAction(
  `Remove Fund From Wallet success`,
  props<{ walletFundId: string }>()
);

export const removeFundFromWalletFailureAction = createAction(
  `Remove Fund From Wallet failure`,
  props<{ error: string }>()
);

export const addFundToWalletAction = createAction(
  `Add Fund to Wallet`,
  props<{
    walletId: string;
    fundId: string;
  }>()
);
export const addFundToWalletSuccessAction = createAction(
  `Add Fund to Wallet success`
);

export const addFundToWalletFailureAction = createAction(
  `Add Fund to Wallet failure`,
  props<{ error: string }>()
);

export const updateWalletNameAction = createAction(
  `Update Wallet Name`,
  props<{
    walletId: string;
    name: string;
  }>()
);
export const updateWalletNameSuccessAction = createAction(
  `Update Wallet Name success`
);

export const updateWalletNameFailureAction = createAction(
  `Update Wallet Name failure`,
  props<{ error: string }>()
);

export const udpateDraftWalletNameAction = createAction(
  `Select wallet name`,
  props<{
    name: string;
  }>()
);

//TODO

export const showSelectedWalletCategoryFunds = createAction(
  `Show selected wallet category funds`,
  props<{
    show: boolean;
  }>()
);

export const loadLoggedUserDevicesAction = createAction(
  `Load logged user devices`
);

export const loadLoggedUserDevicesSuccess = createAction(
  `Load logged user devices success`,
  props<{
    devices: UserDevice[];
  }>()
);

export const loadLoggedUserDevicesFailure = createAction(
  `Load logged user devices failure`,
  props<{ error: string }>()
);

export const deleteUserDevice = createAction(
  `Delete user device`,
  props<{ id: string }>()
);

export const deleteUserDeviceSuccess = createAction(
  `Delete user device success`,
  props<{ id: string }>()
);

export const deleteUserDeviceFailure = createAction(
  `Delete user device failure`,
  props<{ error: string }>()
);

export const loadUsersSubscriptions = createAction(`Load users subscriptions`);

export const loadUsersSubscriptionsSuccess = createAction(
  `Load users subscriptions success`,
  props<{
    usersSubscriptions: UserSubscription[];
  }>()
);

export const loadUsersSubscriptionsFailure = createAction(
  `Load users subscriptions failure`,
  props<{ error: string }>()
);

export const deleteUserSubscription = createAction(
  `Delete user subscription`,
  props<{ id: string }>()
);

export const deleteUserSubscriptionSuccess = createAction(
  `Delete user subscription success`,
  props<{ id: string }>()
);

export const deleteUserSubscriptionFailure = createAction(
  `Delete user subscription failure`,
  props<{ error: string }>()
);

export const loadNotSubscribedUsers = createAction(`Load not subscribed users`);

export const loadNotSubscribedUsersSuccess = createAction(
  `Load not subscribed users success`,
  props<{
    notSubscribedUsers: UserSubscription[];
  }>()
);

export const loadNotSubscribedUsersFailure = createAction(
  `Load not subscribed users failure`,
  props<{ error: string }>()
);

export const addUserSubscription = createAction(
  `Add user subscription`,
  props<{
    userSubscription: Partial<UserSubscription>;
  }>()
);

export const addUserSubscriptionSuccess = createAction(
  `Add user subscription success`,
  props<{
    userSubscription: UserSubscription;
  }>()
);

export const addUserSubscriptionFailure = createAction(
  `Add user subscription failure`,
  props<{ error: string }>()
);

export const createLocalWallet = createAction(`Create Local Wallet`);
