import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface ListItem {
  id: string;
  description: string;
}
@Component({
  selector: 'web-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  @Input()
  itemPrefix = '';
  @Input()
  items: ListItem[] = [];
  @Output()
  selected = new EventEmitter<ListItem>();

  @Input()
  selectedItem: ListItem | undefined;

  selectItem(item: ListItem) {
    this.selectedItem = item;
    if (this.selected) {
      this.selected.emit(item);
    }
  }
}
