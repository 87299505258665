import { filter, first, map, Observable, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { User } from '@metodics/api-client';

import { Facade } from '../+state/facade';

@Injectable()
export class AdminLoggedUserGuard implements CanActivate {
  constructor(private facade: Facade, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.facade.currentUser$.pipe(
      tap((loggedUser: User | undefined) => {
        if (!loggedUser) {
          this.facade.loadCurrentUser();
        }
      }),
      filter((loggedUser) => !!loggedUser),
      map((loggedUser) => {
        return loggedUser?.isAdmin || false;
      }),
      first()
    );
  }
}
