<div ngbDropdown class="d-inline-block" placement="bottom-start bottom-end">
  <button
    type="button"
    class="btn btn-outline-primary d-flex align-items-center"
    id="dropdownBasic1"
    ngbDropdownToggle
  >
    <ng-container
      ><span class="description" *ngIf="selectedItem"
        >{{ itemPrefix }} {{ selectedItem.description }}</span
      >
      <span class="description" *ngIf="!selectedItem">
        Seleziona <span class="bold">{{ itemPrefix }}</span>
      </span>
      <img
        class="ms-auto primary"
        src="../../../../../assets/images/top-performer/chevron-right.svg"
      />
    </ng-container>
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
    <button
      ngbDropdownItem
      *ngFor="let item of items"
      (click)="selectItem(item)"
    >
      <span class="description"
        >{{ itemPrefix }}
        <span [ngClass]="selectedItem?.id === item.id ? 'bold' : ''">{{
          item.description
        }}</span></span
      >
    </button>
  </div>
</div>
