import { debounceTime } from 'rxjs';

import { Component } from '@angular/core';
import { NgrxLoaderFacade } from '@metodics/ngrx-loader';

@Component({
  selector: 'web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'public';
  isLoaderVisible$ = this.loaderFacade.isLoaderVisible$.pipe(debounceTime(250));
  constructor(private loaderFacade: NgrxLoaderFacade) {}
}
