import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiClientConfig } from '../api-client-config';

@Injectable()
export abstract class BaseApiClient<T> {
  abstract baseUrl: string;

  constructor(protected http: HttpClient, protected config: ApiClientConfig) {}
}
