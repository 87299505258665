import * as moment from 'moment';
import { catchError, EMPTY, lastValueFrom, take } from 'rxjs';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentApiClient, SubscriptionType, User } from '@metodics/api-client';
import { AuthService, getDateTime } from '@metodics/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'web-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  @Input()
  loggedUser?: User = undefined;
  @Input()
  subscriptionType: SubscriptionType | undefined = undefined;

  @Output()
  approved = new EventEmitter();

  buttondStyle = {
    shape: 'rect',
    color: 'gold',
    layout: 'vertical',
    label: 'pay',
  };
  constructor(
    private ngbActiveModal: NgbActiveModal,
    private paymentApiClient: PaymentApiClient,
    private authService: AuthService,
    private toastService: ToastService
  ) {}
  ngOnInit(): void {
    (window as any)['paypal']
      .Buttons({
        style: this.buttondStyle,
        createOrder: (data: any, actions: any) =>
          this.createOrder(this, data, actions),
        onApprove: (data: { orderID: string }, actions: any) =>
          this.onApprove(this, data, actions),
      })
      .render('#month-paypal-button-container'); // Renders the PayPal button
    (window as any)['paypal']
      .Buttons({
        style: this.buttondStyle,
        createOrder: (data: any, actions: any) =>
          this.createOrder(this, data, actions),
        onApprove: (data: { orderID: string }, actions: any) =>
          this.onApprove(this, data, actions),
      })
      .render('#year-paypal-button-container'); // Renders the PayPal button
  }
  close() {
    this.ngbActiveModal.close();
  }

  setSubscriptionPlan(type: SubscriptionType) {
    this.subscriptionType = type;
  }
  async createOrder(self: SubscriptionComponent, data: any, actions: any) {
    // console.log('createOrder data', data);
    // console.log('createOrder actions', actions);
    if (self.subscriptionType !== undefined) {
      const orderData = await lastValueFrom(
        self.paymentApiClient
          .orderBySubscriptionType(self.subscriptionType)
          .pipe(take(1))
      );
      console.log('createOrder', orderData);
      return orderData?.id;
    }
    return;
  }
  async onApprove(
    self: SubscriptionComponent,
    data: { orderID: string },
    actions: any
  ) {
    // console.log('onApprove data', data);
    console.log('onApprove data id', data.orderID);
    // console.log('onApprove actions', actions);
    if (data.orderID) {
      const orderData = await lastValueFrom(
        self.paymentApiClient.confirmSubscription(data.orderID).pipe(
          take(1),
          catchError((err) => {
            this.toastService.errorFeedback('', err.statusText);
            return EMPTY;
          })
        )
      );
      console.log('onApprove', orderData);
      if (self.approved) {
        self.approved.emit();
      }
    }
  }
  gotoLogin() {
    this.authService.login();
  }
  getSubscriptionDate(date: string) {
    return getDateTime(
      moment(date).toISOString(),
      'DD/MM/YYYY',
      'HH:mm',
      ' - '
    );
  }
}
