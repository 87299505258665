import { first, map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { FundTotals } from '@metodics/api-client';

import { Facade } from '../+state/facade';

@Injectable()
export class LoadFundTotalsGuard implements CanActivate {
  constructor(private facade: Facade, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.facade.fundTotals$.pipe(
      map((fundTotals: FundTotals | undefined) => {
        if (fundTotals?.stockFunds == 10000) {
          this.facade.loadFundTotals();
        }
        if (fundTotals) {
          this.facade.selectYearAndMonth(
            fundTotals.lastPerformanceUpdatedAt.getFullYear(),
            fundTotals.lastPerformanceUpdatedAt.getMonth() + 1
          );
        }

        return true;
      }),
      first()
    );
  }
}
