import { filter, first, map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Wallet } from '@metodics/api-client';

import { Facade } from '../+state/facade';

@Injectable()
export class LoggedUserWalletsGuard implements CanActivate {
  constructor(private facade: Facade, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.facade.loggedUserWallets$.pipe(
      map((wallets: Wallet[] | undefined) => {
        if (!wallets) {
          const now = new Date();
          this.facade.selectYearAndMonth(now.getFullYear(), now.getMonth());
          this.facade.loadCurrentUserWallets();
        }
        return !!wallets;
      }),
      filter((wallets) => !!wallets),
      first()
    );
  }
}
