import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiClientConfig } from '../api-client-config';
import { FundTotalsDto } from '../dto/fund-totals.dto';
import { FundDto } from '../dto/fund.dto';
import { BaseReadApiClient } from './base-read.api-client';

@Injectable()
export class FundApiClient extends BaseReadApiClient<FundDto> {
  baseUrl = this.config.apiEndpoint + '/api/fund';

  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }

  getByCategory(categoryId: string) {
    return this.http.get<FundDto[]>(`${this.baseUrl}/category/${categoryId}`);
  }

  getTotals() {
    return this.http.get<FundTotalsDto>(`${this.baseUrl}/totals`);
  }
}
