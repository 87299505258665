import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiClientConfig } from '../api-client-config';
import { MacroCategoryDto } from '../dto/macro-category.dto';
import { BaseReadApiClient } from './base-read.api-client';

@Injectable()
export class MacroCategoryApiClient extends BaseReadApiClient<MacroCategoryDto> {
  baseUrl = this.config.apiEndpoint + '/api/macro-category';

  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }
  override getAll() {
    return this.http.get<MacroCategoryDto[]>(this.baseUrl);
  }
}
