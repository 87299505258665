import { Injectable } from '@angular/core';
import { SubscriptionType, User } from '@metodics/api-client';
import { SubscriptionComponent } from '@metodics/ui-kit';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class SubscriptionService {
  modalRef?: NgbModalRef;

  constructor(private modalService: NgbModal) {}
  showModal(
    subscriptionType: SubscriptionType | undefined,
    loggedUser: User | undefined,
    onApproved: () => void
  ) {
    this.modalRef = this.modalService.open(SubscriptionComponent, {
      modalDialogClass: 'subscription-modal',
    });
    this.modalRef.componentInstance.loggedUser = loggedUser;
    this.modalRef.componentInstance.subscriptionType = subscriptionType;
    this.modalRef.componentInstance.approved.subscribe(async () => {
      if (onApproved) {
        onApproved();
      }
      this.modalRef?.close();
    });
  }
}
