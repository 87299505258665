import * as moment from 'moment';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Category } from '@metodics/api-client';
import { getDate } from '@metodics/common';
import { MacroCategoryListItem } from '../macro-category-list/macro-category-list.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'web-category-grid',
  templateUrl: './category-grid.component.html',
  styleUrls: ['./category-grid.component.scss'],
})
export class CategoryGridComponent implements OnChanges {
  @Input()
  macroCategory?: Partial<MacroCategoryListItem> = undefined;
  @Input()
  categories?: Category[] = undefined;
  @Input()
  selectedCategoryId?: string;
  @Input()
  expandible = false;

  date = getDate(moment().toISOString(), 'DD/MM/YYYY');
  expanded = true;

  @Output()
  selected = new EventEmitter<string>();

  searchText = '';

  searchFormControl = new FormControl('');

  filteredCategories?: Category[] = undefined;

  onClick(category: Category) {
    if (this.selected) {
      this.selected.emit(category.id);
    }
  }

  searchChanged(value: string) {
    this.searchText = value;
  }
  search() {
    this.filteredCategories = this.categories?.filter((c) =>
      c.name.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  cancelSearch() {
    this.filteredCategories = this.categories;
    this.searchText = '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filteredCategories = this.categories;
  }
}
