import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { of, switchMap } from 'rxjs';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from '@metodics/common';

import { Facade } from '../../+state/facade';
import { Router } from '@angular/router';

@Component({
  selector: 'web-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  signedIn$ = this.authService.signedIn$;
  isCollapsed = false;
  currentUser$ = this.facade.currentUser$;

  constructor(
    private authService: AuthService,
    private facade: Facade,
    public oidcSecurityService: OidcSecurityService,
    private router: Router
  ) {}

  ngOnInit(): void {
    //0-account-console - silent renew failed! Error: Error: authorizedCallback, token(s) validation failed, resetting. Hash:
    this.oidcSecurityService
      .checkAuth()
      .pipe(
        switchMap((loginResponse: LoginResponse) => {
          if (!loginResponse.isAuthenticated) {
            //return this.oidcSecurityService.forceRefreshSession();
          }
          return of(loginResponse);
        })
      )
      .subscribe({
        next: (loginResponse: LoginResponse) => {
          if (loginResponse.isAuthenticated) {
            this.facade.loadCurrentUser();
            this.navigateToStoredEndpoint();
          }
          console.log(
            'checkAuth',
            loginResponse.isAuthenticated,
            loginResponse.accessToken
          );
          /*...*/
        },
        error: (error) => {
          console.log('checkAuth or forceRefreshSession', error);
        },
        complete: () => console.log('checkAuth completed'),
      });
  }

  login() {
    this.authService.login();
  }
  logout() {
    this.authService.logout();
  }

  private navigateToStoredEndpoint() {
    const path = this.read('redirect');

    if (this.router.url === path) {
      return;
    }

    this.write('redirect', '/');

    if (path.toString().includes('/unauthorized')) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate([path]);
    }
  }

  private write(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private read(key: string): any {
    const data = localStorage.getItem(key);
    if (data != null) {
      return JSON.parse(data);
    }

    return;
  }
}
