import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiClientConfig } from '../api-client-config';
import { CreateWalletRequestDto } from '../dto/create-wallet-request.dto';
import { WalletDto } from '../dto/wallet.dto';
import { Wallet } from '../entities/wallet.entity';
import { BaseCRUDApiClient } from './base-crud.api-client';

@Injectable()
export class WalletApiClient extends BaseCRUDApiClient<WalletDto> {
  baseUrl = this.config.apiEndpoint + '/api/wallet';

  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }

  getForCurrentUser(year: number, month: number) {
    const headers = {
      'x-year': year.toString(),
      'x-month': month.toString(),
    };
    return this.http.get<WalletDto[]>(`${this.baseUrl}/current-user`, {
      headers: headers,
    });
  }

  create(body: CreateWalletRequestDto) {
    return this.http.post<Wallet>(this.baseUrl, body);
  }

  deleteFundFromWallet(walletFundId: string) {
    return this.http.delete(`${this.baseUrl}/wallet-fund/${walletFundId}`);
  }

  addFundToWallet(walletId: string, fundId: string) {
    return this.http.put(`${this.baseUrl}/${walletId}/${fundId}`, {});
  }

  udpateWalletName(walletId: string, name: string) {
    return this.http.put(`${this.baseUrl}/update-name`, {
      walletId: walletId,
      name: name,
    });
  }
}
