<ng-container *ngIf="macroCategory && categories; else noInfo">
  <table class="overflow-x-auto flex-grow-1">
    <thead>
      <tr class="title">
        <td colspan="9">
          <div class="d-flex">
            <div class="flex-grow-1 d-flex">
              <span class="description">Report Categorie Metodics</span>
              <span class="label"> Macro Categoria:</span>
              <span class="separator"></span>
              <span class="macro-category"> {{ macroCategory.title }}</span>
            </div>
            <div *ngIf="expandible">
              <!-- <span
              class="material-icons-outlined cursor-pointer up"
              *ngIf="expanded"
              (click)="expanded = !expanded"
            >
              expand_circle_down
            </span>
            <span
              class="material-icons-outlined cursor-pointer down"
              *ngIf="!expanded"
              (click)="expanded = !expanded"
            >
              expand_circle_down
            </span> -->
              <img
                class="primary chevron"
                [class.up]="expanded"
                [class.down]="!expanded"
                (click)="expanded = !expanded"
                src="/assets/images/chevron-right.svg"
              />
            </div>
          </div>
        </td>
      </tr>
      <tr class="subtitle">
        <th colspan="2">
          <div class="d-flex search-box">
            <web-input
              class="search-input"
              [maxlength]="27"
              [formControl]="searchFormControl"
              ngDefaultControl
              [placeholder]="'Cerca categoria per nome'"
              (textValueChange)="searchChanged($event)"
              [textValue]="searchText"
            ></web-input>
            <button
              type="button"
              class="btn btn-sm btn-primary mx-2 border"
              (click)="search()"
            >
              <i class="material-icons add-wallet-icon px-2">search</i>
            </button>
            <button
              type="button"
              class="btn btn-sm btn-primary mx-2 border"
              (click)="cancelSearch()"
            >
              <i class="material-icons add-wallet-icon px-2">cancel</i>
            </button>
          </div>
        </th>
        <th colspan="3" class="three-months col-header">Performance 3 mesi</th>
        <th colspan="3" class="one-year col-header">Performance 12 mesi</th>
      </tr>
      <tr>
        <th class="category-name">Nome categoria</th>
        <th class="total-funds">Nr fondi della categoria</th>
        <th class="three-months top-rating">
          <div class="d-flex">
            <span> Top rating Metodics </span>
            <i class="material-icons sort-column">arrow_circle_down</i>
          </div>
        </th>
        <th class="three-months category-average">Media della categoria</th>
        <th class="three-months msci-index">Indice MSCI azioni</th>
        <th class="one-year top-rating">Top rating Metodics</th>
        <th class="one-year category-average">Media della categoria</th>
        <th class="one-year msci-index">Indice MSCI azioni</th>
      </tr>
    </thead>
    <ng-container *ngIf="expanded">
      <tbody>
        <tr
          *ngFor="let item of filteredCategories; index as itemIndex"
          [class.even]="itemIndex % 2 === 0"
          (click)="onClick(item)"
          [class.selected]="item.id === selectedCategoryId"
        >
          <td class="category-name text-start ps-2">
            {{ item.name }}
          </td>
          <td class="total-funds">{{ item.lastTotalFunds }}</td>
          <td class="three-months top-rating">
            {{
              item.last3MonthsTopRatingFundPerformanceAverage * 100
                | number : '1.2-2' : 'it-IT'
            }}
            %
          </td>
          <td class="three-months category-average">
            {{
              item.last3MonthsPerformanceAverage * 100
                | number : '1.2-2' : 'it-IT'
            }}
            %
          </td>
          <td class="three-months msci-index">
            {{ item.last3MonthsMSCIIndex * 100 | number : '1.2-2' : 'it-IT' }}
            %
          </td>
          <td class="one-year top-rating">
            {{
              item.last12MonthsTopRatingFundPerformanceAverage * 100
                | number : '1.2-2' : 'it-IT'
            }}
            %
          </td>
          <td class="one-year category-average">
            {{
              item.last12MonthsPerformanceAverage * 100
                | number : '1.2-2' : 'it-IT'
            }}
            %
          </td>
          <td class="one-year msci-index">
            {{ item.last12MonthsMSCIIndex * 100 | number : '1.2-2' : 'it-IT' }}
            %
          </td>
        </tr>
      </tbody>
    </ng-container>
  </table>
</ng-container>
<ng-template #noInfo>Nessuna categoria</ng-template>
