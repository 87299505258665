import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiClientConfig } from '../api-client-config';
import { BaseDto } from '../dto/base.dto';
import { SubscriptionType } from '../dto/subscription-type';
import { BaseApiClient } from './base.api-client';

@Injectable()
export class PaymentApiClient extends BaseApiClient<BaseDto> {
  baseUrl = this.config.apiEndpoint + '/api/payment';

  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }

  orderBySubscriptionType(subscriptionType: SubscriptionType) {
    return this.http.post<BaseDto>(
      `${this.baseUrl}/order/${subscriptionType}`,
      {}
    );
  }

  confirmSubscription(orderId: string) {
    return this.http.post<BaseDto>(
      `${this.baseUrl}/order/${orderId}/capture`,
      {}
    );
  }
}
