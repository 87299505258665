import { cloneDeep } from 'lodash';
import * as moment from 'moment';

import {
  Category,
  Fund,
  FundTotals,
  MacroCategory,
  User,
  UserDevice,
  UserSubscription,
  Wallet,
} from '@metodics/api-client';
import { getDateTime } from '@metodics/common';
import { createReducer, on } from '@ngrx/store';

import * as MainActions from './actions';

export const MAIN_FEATURE_KEY = 'main';

export interface ExtendedFund extends Fund {
  showReport: boolean;
  reportPanelIsExpanded: boolean;
  showDetail: boolean;
  detailsPanelIsExpanded: boolean;
  category?: (Category & { funds?: Fund[] }) | undefined;
  macroCategory?: MacroCategory | undefined;
}
export interface State {
  macroCategories: MacroCategory[];
  currentUser: User | undefined;
  selectedMacroCategoryId: string | undefined;
  categories: Category[];
  selectedCategoryId: string | undefined;
  funds: Fund[];
  fundTotals: FundTotals | undefined;
  selectedYearAndMonth: { year: number; month: number };
  comparingFundsSettings: {
    fundId: string;
    fundIsin: string;
    mode: 'FUND_DETAILS' | 'CATEGORY_REPORT';
    categoryReportPanelIsExpanded: boolean;
    fundDetailsPanelIsExpanded: boolean;
  }[];

  draftWallet: {
    fundsIds: string[];
    name: string;
    dateTimeDescription: string;
  };

  //TODO
  loggedUser: {
    wallets: Wallet[] | undefined;
    devices: UserDevice[] | undefined;
  };

  usersSubscriptions: UserSubscription[] | undefined;
  notSubscribedUsers: UserSubscription[] | undefined;
}

export interface MainState {
  readonly [MAIN_FEATURE_KEY]: State;
}

const initialState: State = {
  macroCategories: [],
  selectedMacroCategoryId: undefined,
  currentUser: undefined,
  selectedCategoryId: undefined,
  funds: [],
  categories: [],
  comparingFundsSettings: [],
  fundTotals: {
    stockFunds: 10000,
    totals: 30000,
    lastPerformanceUpdatedAt: new Date(),
  },
  selectedYearAndMonth: {
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  },

  //TODO
  loggedUser: {
    wallets: undefined,
    devices: undefined,
  },
  draftWallet: {
    fundsIds: [],
    name: 'Portafoglio',
    dateTimeDescription: '',
  },
  usersSubscriptions: undefined,
  notSubscribedUsers: undefined,
};

export const reducer = createReducer(
  initialState,
  on(
    MainActions.loadMacroCategoriesSuccessAction,
    (state, { macroCategories }) => {
      const newState = cloneDeep(state);
      newState.macroCategories = macroCategories;
      return newState;
    }
  ),
  on(MainActions.loadFundTotalsSuccessAction, (state, { fundTotals }) => {
    const newState = cloneDeep(state);
    newState.fundTotals = {
      totals: fundTotals.totals,
      stockFunds: fundTotals.stockFunds,
      lastPerformanceUpdatedAt: new Date(fundTotals.lastPerformanceUpdatedAt),
    };
    return newState;
  }),
  on(
    MainActions.loadCurrentUserSuccessAction,
    (state, { currentUser: loggedUser }) => {
      const newState = cloneDeep(state);
      newState.currentUser = loggedUser;
      return newState;
    }
  ),

  on(MainActions.selectMacroCategoryIdAction, (state, { macroCategoryId }) => {
    const newState = cloneDeep(state);
    newState.selectedMacroCategoryId = macroCategoryId;
    return newState;
  }),

  on(
    MainActions.loadCategoriesBySelectedMacroCategoryIdSuccessAction,
    (state, { categories }) => {
      const newState = cloneDeep(state);
      for (const category of categories) {
        if (newState.categories.find((c) => c.id === category.id) == null) {
          newState.categories.push(category);
        }
      }
      return newState;
    }
  ),

  on(MainActions.selectCategoryIdAction, (state, { categoryId }) => {
    const newState = cloneDeep(state);
    newState.selectedCategoryId = categoryId;
    return newState;
  }),

  on(
    MainActions.loadFundsBySelectedCategorySuccessAction,
    (state, { funds }) => {
      const newState = cloneDeep(state);
      for (const fund of funds) {
        if (newState.funds.find((c) => c.id === fund.id) == null) {
          newState.funds.push(fund);
        }
      }
      return newState;
    }
  ),
  on(
    MainActions.loadFundsBySelectedCategorySuccessAction,
    (state, { funds }) => {
      const newState = cloneDeep(state);
      for (const fund of funds) {
        if (newState.funds.find((c) => c.id === fund.id) == null) {
          newState.funds.push(fund);
        }
      }
      return newState;
    }
  ),
  on(MainActions.addFundToCompareListAction, (state, { fund }) => {
    const newState = cloneDeep(state);
    if (
      newState.comparingFundsSettings.find((x) => x.fundId === fund.id) == null
    ) {
      newState.comparingFundsSettings.push({
        fundId: fund.id,
        fundIsin: fund.isin,
        fundDetailsPanelIsExpanded: true,
        mode: 'FUND_DETAILS',
        categoryReportPanelIsExpanded: true,
      });
    }

    return newState;
  }),

  on(MainActions.loadFundSuccessAction, (state, { fund }) => {
    const newState = cloneDeep(state);
    if (newState.funds.find((c) => c.id === fund.id) == null) {
      newState.funds.push(fund);
    }
    return newState;
  }),

  on(MainActions.updateComparedFundModeAction, (state, { fundId, mode }) => {
    const newState = cloneDeep(state);
    const item = newState.comparingFundsSettings.find(
      (c) => c.fundId === fundId
    );
    if (item != null) {
      item.mode = mode;
      item.categoryReportPanelIsExpanded = true;
      item.fundDetailsPanelIsExpanded = true;
    }
    return newState;
  }),
  on(
    MainActions.updateComparedFundCategoryReportPanelExpandedStatusAction,
    (state, { fundId, isExpanded }) => {
      const newState = cloneDeep(state);
      const item = newState.comparingFundsSettings.find(
        (c) => c.fundId === fundId
      );
      if (item != null) {
        item.categoryReportPanelIsExpanded = isExpanded;
      }
      return newState;
    }
  ),
  on(
    MainActions.updateComparedFundDetailPanelExpandedStatusAction,
    (state, { fundId, isExpanded }) => {
      const newState = cloneDeep(state);
      const item = newState.comparingFundsSettings.find(
        (c) => c.fundId === fundId
      );
      if (item != null) {
        item.fundDetailsPanelIsExpanded = isExpanded;
      }
      return newState;
    }
  ),

  on(MainActions.loadCategorySuccessAction, (state, { category }) => {
    const newState = cloneDeep(state);
    if (newState.categories.find((c) => c.id === category.id) == null) {
      newState.categories.push(category);
    }
    return newState;
  }),
  on(MainActions.addFundToDraftWalletAction, (state, { fundId }) => {
    const newState = cloneDeep(state);
    if (!newState.draftWallet.fundsIds.includes(fundId)) {
      newState.draftWallet.fundsIds.push(fundId);
    }
    return newState;
  }),
  on(MainActions.removeFundFromCompareListAction, (state, { fundId }) => {
    const newState = cloneDeep(state);
    newState.comparingFundsSettings = newState.comparingFundsSettings.filter(
      (x) => x.fundId !== fundId
    );

    return newState;
  }),

  on(MainActions.removeFundFromDraftWalletAction, (state, { fundId }) => {
    const newState = cloneDeep(state);

    newState.draftWallet.fundsIds = newState.draftWallet.fundsIds.filter(
      (x) => x != fundId
    );

    return newState;
  }),

  on(MainActions.selectYearAndMonthAction, (state, { year, month }) => {
    const newState = cloneDeep(state);

    newState.selectedYearAndMonth = {
      year,
      month,
    };

    return newState;
  }),

  on(MainActions.loadLoggedUserWalletsSuccessAction, (state, { wallets }) => {
    const newState = cloneDeep(state);
    newState.loggedUser.wallets = wallets;
    return newState;
  }),

  on(MainActions.deleteWalletSuccessAction, (state, { id }) => {
    const newState = cloneDeep(state);
    newState.loggedUser.wallets = [...(state.loggedUser.wallets || [])].filter(
      (w) => w.id !== id
    );
    return newState;
  }),

  //DA VERIFICARE

  on(MainActions.showSelectedWalletCategoryFunds, (state, { show }) => {
    return {
      ...state,
      draftWallet: { ...state.draftWallet, showCategoryFunds: show },
    };
  }),

  on(MainActions.udpateDraftWalletNameAction, (state, { name }) => {
    const newState = cloneDeep(state);
    newState.draftWallet.name = name;
    return newState;
  }),
  on(MainActions.createWalletSuccessAction, (state) => {
    const newState = cloneDeep(state);
    newState.draftWallet = {
      fundsIds: [],
      dateTimeDescription: `${getDateTime(
        moment().toISOString(),
        'DD/MM/YYYY',
        'HH:mm',
        ' - '
      )}`,
      name: 'Portafoglio',
    };
    newState.loggedUser.wallets = undefined;
    return newState;
  }),

  on(MainActions.loadLoggedUserDevicesSuccess, (state, { devices }) => {
    const newState = cloneDeep(state);
    newState.loggedUser.devices = devices;

    return newState;
  }),
  on(MainActions.deleteUserDeviceSuccess, (state, { id }) => {
    const newState = cloneDeep(state);
    newState.loggedUser.devices = [...(state.loggedUser.devices || [])].filter(
      (w) => w.id !== id
    );
    return newState;
  }),
  on(
    MainActions.loadUsersSubscriptionsSuccess,
    (state, { usersSubscriptions }) => {
      return {
        ...state,
        usersSubscriptions,
      };
    }
  ),
  on(MainActions.deleteUserSubscriptionSuccess, (state, { id }) => {
    return {
      ...state,
      usersSubscriptions: state.usersSubscriptions?.filter((w) => w.id !== id),
    };
  }),
  on(
    MainActions.loadNotSubscribedUsersSuccess,
    (state, { notSubscribedUsers }) => {
      return {
        ...state,
        notSubscribedUsers,
      };
    }
  ),

  on(MainActions.createLocalWallet, (state) => {
    return {
      ...state,
      draftWallet: {
        ...state.draftWallet,
        name: !state.draftWallet.name ? 'Portafoglio' : state.draftWallet.name,
        dateTimeDescription: !state.draftWallet.dateTimeDescription
          ? `${getDateTime(
              moment().toISOString(),
              'DD/MM/YYYY',
              'HH:mm',
              ' - '
            )}`
          : state.draftWallet.dateTimeDescription,
      },
    };
  })
);
