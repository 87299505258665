import { ToastrModule } from 'ngx-toastr';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { PushModule } from '@ngrx/component';

import { ButtonComponent } from './button/button.component';
import { CategoryGridComponent } from './category-grid/category-grid.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { FundGridComponent } from './fund-grid/fund-grid.component';
import { InputComponent } from './input/input.component';
import { LoaderComponent } from './loader/loader.component';
import { MacroCategoryListComponent } from './macro-category-list/macro-category-list.component';
import { PanelComponent } from './panel/panel.component';
import { SelectComponent } from './select/select.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { ToastComponent } from './toast/toast.component';
import { WalletFundGridComponent } from './wallet-fund-grid/wallet-fund-grid.component';
import { WalletComponent } from './wallet/wallet.component';
import { WalletSelectorComponent } from './wallet-selector/wallet-selector.component';
import { NumberFormatter } from './custom-pipes/number-formatter';

@NgModule({
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      tapToDismiss: true,
    }),
    ReactiveFormsModule,
    NgbRatingModule,
    FormsModule,
    NgbDropdownModule,
  ],
  declarations: [
    LoaderComponent,
    PanelComponent,
    ToastComponent,
    ButtonComponent,
    InputComponent,
    FundGridComponent,
    CheckboxComponent,
    CategoryListComponent,
    WalletFundGridComponent,
    WalletComponent,
    SubscriptionComponent,
    SelectComponent,
    CategoryGridComponent,
    MacroCategoryListComponent,
    WalletSelectorComponent,
    NumberFormatter,
  ],
  exports: [
    LoaderComponent,
    PanelComponent,
    PushModule,
    ButtonComponent,
    InputComponent,
    FundGridComponent,
    NgbRatingModule,
    CheckboxComponent,
    CategoryListComponent,
    WalletFundGridComponent,
    WalletComponent,
    SubscriptionComponent,
    SelectComponent,
    CategoryGridComponent,
    MacroCategoryListComponent,
    WalletSelectorComponent,
    NumberFormatter,
  ],
})
export class UikitModule {}
