import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Category,
  Fund,
  MacroCategory,
  WalletFundDto,
} from '@metodics/api-client';

@Component({
  selector: 'web-wallet-fund-grid',
  templateUrl: './wallet-fund-grid.component.html',
  styleUrls: ['./wallet-fund-grid.component.scss'],
})
export class WalletFundGridComponent {
  @Input()
  macroCategory?: Partial<MacroCategory> = undefined;
  @Input()
  category?: Partial<Category> = undefined;
  @Input()
  funds?: (WalletFundDto & { selected?: boolean })[] = undefined;
  @Input()
  canDelete = false;
  @Input()
  gridType: 'simple' | 'extended' = 'simple';
  @Input()
  showMacroCategory = false;

  @Output()
  deleted = new EventEmitter<WalletFundDto>();

  delete(fund: WalletFundDto) {
    if (this.deleted) {
      this.deleted.emit(fund);
    }
  }
}
