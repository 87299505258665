import { filter, first, map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserDevice } from '@metodics/api-client';

import { Facade } from '../+state/facade';

@Injectable()
export class LoggedUserDevicesGuard implements CanActivate {
  constructor(private facade: Facade, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.facade.getLoggedUserDevices$.pipe(
      map((userDevices: UserDevice[] | undefined) => {
        if (!userDevices) {
          this.facade.loadLoggedUserDevices();
        }
        return !!userDevices;
      }),
      filter((userDevices) => !!userDevices),
      first()
    );
  }
}
