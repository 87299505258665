import { first, Observable, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@metodics/common';

import { Facade } from '../+state/facade';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private facade: Facade
  ) {}

  canActivate(): Observable<boolean> {
    return this.authService.signedIn$.pipe(
      tap((isAuthenticated) => {
        if (!isAuthenticated) {
          //this.router.navigateByUrl('');
          this.authService.login();
        }
      }),
      first()
    );
  }
}
