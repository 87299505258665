import {
  AbstractSecurityStorage,
  AuthModule,
  LogLevel,
} from 'angular-auth-oidc-client';
import * as moment from 'moment';

import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiClientModule } from '@metodics/api-client';
import {
  AuthService,
  httpInterceptorProviders,
  SubscriptionService,
} from '@metodics/common';
import { NgrxLoaderModule } from '@metodics/ngrx-loader';
import { UikitModule } from '@metodics/ui-kit';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';
import { Effects } from './+state/effects';
import { Facade } from './+state/facade';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './containers/layout/layout.component';
import { CustomStorage } from './custom-storage';
import { AdminLoggedUserGuard } from './guards/admin-logged-user.guard';
import { LoadAllMacroCategoryGuard } from './guards/load-all-macro-category.guard';
import { LoadAllNotSubscribedUsersGuard } from './guards/load-all-not-subscribed-users.guard';
import { LoadAllUsersSubscriptionsGuard } from './guards/load-all-users-subscription.guard';
import { LoadFundTotalsGuard } from './guards/load-fund-totals.guard';
import { LoggedUserDevicesGuard } from './guards/logged-user-devices.guard';
import { LoggedUserWalletsGuard } from './guards/logged-user-wallets.guard';
import { LoggedUserGuard } from './guards/logged-user.guard';
import { LoginGuard } from './guards/login.guard';
import { SubscribedLoggedUserGuard } from './guards/subscribed-logged-user';
import { ValidLoggedUserGuard } from './guards/valid-logged-user.guard';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    //routes
    AppRoutingModule,
    //ngrx store
    StoreModule.forRoot(
      {},
      {
        // metaReducers,
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([Effects]),
    ReactiveFormsModule,
    UikitModule,
    NgrxLoaderModule,
    NgbCollapseModule,
    HttpClientModule,
    ApiClientModule.forRoot({
      apiEndpoint: environment.baseApiUrl,
    }),
    AuthModule.forRoot({
      config: {
        authority: environment.baseAuthUrl + '/realms/metodics-realm',
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: 'account-console',
        scope: 'openid profile email',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        logLevel: LogLevel.Debug,
        ignoreNonceAfterRefresh: true,
        customParamsAuthRequest: { ui_locales: 'it' },
      },
    }),
  ],
  providers: [
    Facade,
    httpInterceptorProviders,
    AuthService,
    LoggedUserGuard,
    LoginGuard,
    LoggedUserWalletsGuard,
    LoggedUserDevicesGuard,
    AdminLoggedUserGuard,
    SubscribedLoggedUserGuard,
    LoadAllUsersSubscriptionsGuard,
    LoadAllNotSubscribedUsersGuard,
    LoadAllMacroCategoryGuard,
    ValidLoggedUserGuard,
    { provide: AbstractSecurityStorage, useClass: CustomStorage },
    // { provide: AbstractSecurityStorage, useClass: DefaultLocalStorageService },
    SubscriptionService,
    LoadFundTotalsGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeIt);
    moment.locale('it');
  }
}
