import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'web-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input()
  label = '';
  @Input()
  withChevron = false;
  @Input()
  withChevronAnimation = false;
  @Input()
  styleType: 'primary' | 'primary-inverted' | 'primary-inverted-dark' =
    'primary';
  @Input()
  disabled = false;

  @Output()
  clicked = new EventEmitter();

  onClick() {
    if (this.clicked) {
      this.clicked.emit();
    }
  }
}
