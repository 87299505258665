<div>
  <div *ngIf="showTitle" class="title text-center mb-4">
    Macrocategorie <span class="primary-color">Metodics</span>
  </div>
  <div class="d-flex flex-wrap justify-content-center">
    <div
      class="outer"
      [class.selected]="isSelected(item)"
      [class.cliccable]="isCliccable()"
      *ngFor="let item of items"
      (click)="onClick(item)"
    >
      <div
        class="inner d-flex flex-column justify-content-center align-items-center"
      >
        <div class="description-title text-center">
          {{ item.title }}
        </div>
        <div class="description">
          {{ item.totalCategories | numberFormatter }} categorie
        </div>
        <div class="description">
          {{ item.totalFunds | numberFormatter }} fondi
        </div>
      </div>
    </div>
  </div>
</div>
