<ng-container
  *ngIf="macroCategory && category && funds; else noInfo"
  class="overflow-x-auto"
>
  <table class="overflow-x-auto flex-grow-1">
    <tr class="title" *ngIf="showMacroCategory">
      <td [attr.colspan]="canDelete ? 14 : 13">
        <span class="categories">Macro Categoria:</span>
        <span class="separator"></span>
        <span class="macro-category">{{ macroCategory.name }}</span>
      </td>
    </tr>
    <tr class="title-breadcrumb">
      <td [attr.colspan]="canDelete ? 14 : 13">
        <div class="d-flex">
          <span class="categories">Categoria:</span>
          <span class="separator"></span>
          <span class="category">{{ category.name }}</span>
        </div>
      </td>
    </tr>
    <tr class="subtitle">
      <th [attr.colspan]="canDelete ? 6 : 5"></th>
      <th colspan="4" class="three-months">Performance 3 mesi</th>
      <th colspan="4" class="one-year">Performance 12 mesi</th>
    </tr>
    <!-- <tr class="subtitle">
        <th *ngIf="showSelect"></th>
        <th colspan="3">carta identità</th>
        <th colspan="3">dati metodics</th>
        <th colspan="3">dati di mercato</th>
        <th>dati metodics</th>
        <th colspan="3">dati di mercato</th>
      </tr> -->
    <tr>
      <th class="fund-delete" *ngIf="canDelete">Rimuovi</th>
      <th class="fund-name">Nome Fondo</th>
      <th class="fund-isin">Isin</th>
      <th class="fund-currency">Valuta</th>
      <th>Ranking Metodics</th>
      <th>Rating Metodics</th>
      <th class="three-months">Performance</th>
      <th class="three-months">Top rating Metodics</th>
      <th class="three-months">Media della categoria</th>
      <th class="three-months">Indice MSCI azioni</th>
      <th class="one-year">Performance</th>
      <th class="one-year">Top rating metodics</th>
      <th class="one-year">Media della categoria</th>
      <th class="one-year">Indice MSCI azioni</th>
    </tr>
    <tr
      *ngFor="let fund of funds; index as fundIndex"
      [class.even]="fundIndex % 2 === 0"
    >
      <td class="fund-delete" *ngIf="canDelete">
        <span class="material-icons-outlined delete" (click)="delete(fund)">
          delete
        </span>
      </td>
      <td class="fund-name">
        {{ fund.name }}
      </td>
      <td class="fund-isin">
        {{ fund.isin }}
      </td>
      <td class="fund-currency">
        {{ fund.currency }}
      </td>
      <td>{{ fund.lastRanking }}</td>
      <td>
        <ngb-rating
          [max]="+(fund.lastRating || '')"
          [rate]="+(fund.lastRating || '')"
          [readonly]="true"
        ></ngb-rating>
      </td>
      <td class="three-months">
        {{
          fund.last3MonthsPerformanceAverage * 100 | number : '1.2-2' : 'it-IT'
        }}
        %
      </td>
      <td class="three-months">
        {{
          (category.last3MonthsTopRatingFundPerformanceAverage ?? 0) * 100
            | number : '1.2-2' : 'it-IT'
        }}
        %
      </td>
      <td class="three-months">
        {{
          (category.last3MonthsPerformanceAverage ?? 0) * 100
            | number : '1.2-2' : 'it-IT'
        }}
        %
      </td>
      <td class="three-months">
        {{
          (category.last3MonthsMSCIIndex ?? 0) * 100
            | number : '1.2-2' : 'it-IT'
        }}
        %
      </td>
      <td class="one-year">
        {{
          (fund?.last12MonthsPerformanceAverage ?? 0) * 100
            | number : '1.2-2' : 'it-IT'
        }}
        %
      </td>
      <td class="one-year">
        {{
          (category.last12MonthsTopRatingFundPerformanceAverage ?? 0) * 100
            | number : '1.2-2' : 'it-IT'
        }}
        %
      </td>
      <td class="one-year">
        {{
          (category.last12MonthsPerformanceAverage ?? 0) * 100
            | number : '1.2-2' : 'it-IT'
        }}
        %
      </td>
      <td class="one-year">
        {{
          (category.last12MonthsMSCIIndex ?? 0) * 100
            | number : '1.2-2' : 'it-IT'
        }}
        %
      </td>
      <!-- <td class="fund-rating">
        <ngb-rating
          *ngIf="fund.rating !== '***'; else elseBlock"
          [max]="+fund.rating"
          [rate]="+fund.rating"
          [readonly]="true"
          class="rate"
        ></ngb-rating>
        <ng-template #elseBlock>{{ fund.rating }}</ng-template>
      </td> -->
    </tr>
  </table>
</ng-container>
<ng-template #noInfo>Nessun fondo</ng-template>
