import { filter, first, map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { User } from '@metodics/api-client';

import { Facade } from '../+state/facade';
import { ToastService } from '@metodics/ui-kit';

@Injectable()
export class ValidLoggedUserGuard implements CanActivate {
  constructor(
    private facade: Facade,
    private router: Router,
    private toastService: ToastService
  ) {}

  canActivate(): Observable<boolean> {
    return this.facade.currentUser$.pipe(
      map((loggedUser: User | undefined) => {
        let valid = false;
        if (!loggedUser) {
          this.facade.loadCurrentUser();
        } else {
          valid = !loggedUser.deviceLimitExceeded;
          if (loggedUser.deviceLimitExceeded) {
            const isDevicesLimitExceeded = loggedUser.deviceLimitExceeded;
            if (isDevicesLimitExceeded === true) {
              this.toastService.errorFeedback(
                'Attenzione',
                'Hai superato il numero massimo di dispositivi consentiti. Elimina un dispositivo dalla sezione "I miei dispositivi"',
                false
              );
              this.router.navigateByUrl('miei-dispositivi');
            }
          }
        }
        return valid;
      }),
      filter((loggedUser) => !!loggedUser),
      first()
    );
  }
}
