import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiClientConfig } from '../api-client-config';
import { SubscriptionType } from '../dto/subscription-type';
import { UserSubscriptionDto } from '../dto/user-subscription.dto';
import { BaseCRUDApiClient } from './base-crud.api-client';

@Injectable()
export class UserSubscriptionApiClient extends BaseCRUDApiClient<UserSubscriptionDto> {
  baseUrl = this.config.apiEndpoint + '/api/user-subscription';

  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }

  getNotSubscribed() {
    return this.http.get<UserSubscriptionDto[]>(
      `${this.baseUrl}/user/not-subscribed`
    );
  }
  addSubscription(
    userId: string,
    subscriptionType: SubscriptionType | undefined
  ) {
    return this.http.post<UserSubscriptionDto>(
      `${this.baseUrl}?UserId=${userId}&SubscriptionType=${subscriptionType}`,
      {}
    );
  }
}
