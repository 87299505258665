import { filter, first, map, Observable, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Facade } from '../+state/facade';

@Injectable()
export class LoadAllMacroCategoryGuard implements CanActivate {
  constructor(private facade: Facade) {}

  canActivate(): Observable<boolean> {
    return this.facade.getMacroCategoriesIsLoaded$.pipe(
      map((loaded: boolean | undefined) => {
        if (!loaded) {
          this.facade.loadMacroCategories();
        }
        return true;
      }),
      //filter((loaded) => loaded),
      //map(() => true),
      first()
    );
  }
}
