import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiClientConfig } from '../api-client-config';
import { BaseReadApiClient } from './base-read.api-client';

@Injectable()
export abstract class BaseCRUDApiClient<T> extends BaseReadApiClient<T> {
  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }

  post(body: Partial<T>) {
    return this.http.post<T>(this.baseUrl, body);
  }

  delete(id: string) {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }
}
