<ng-template #menuRef>
  <ul class="navbar-nav flex-wrap mb-xl-0 mb-3 ms-3">
    <li class="nav-item">
      <a
        class="nav-link"
        [routerLink]="['/confronta-fondi']"
        routerLinkActive="active"
        (click)="isCollapsed = true"
        >Confronta i tuoi fondi</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        [routerLink]="['/rating-fondi-azionari']"
        routerLinkActive="active"
        (click)="isCollapsed = true"
        >Rating fondi azionari</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        [routerLink]="['/miei-portafogli']"
        routerLinkActive="active"
        (click)="isCollapsed = true"
        >I tuoi portafogli</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        [routerLink]="['/nostro-rating']"
        routerLinkActive="active"
        (click)="isCollapsed = true"
        >Il nostro rating</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        [routerLink]="['/educazione-finanziaria']"
        routerLinkActive="active"
        (click)="isCollapsed = true"
        >Educazione finanziaria</a
      >
    </li>
  </ul>
</ng-template>
<div class="sticky-wrapper sticky-top">
  <nav class="navbar navbar-expand-xl">
    <div class="container-xxl position-relative d-flex">
      <a
        class="navbar-brand me-0"
        [routerLink]="['/']"
        (click)="isCollapsed = true"
        ><img src="assets/images/logo.png" alt="metodics" />
      </a>
      <div class="flex-grow-1 menu">
        <div class="collapse navbar-collapse">
          <ng-template [ngTemplateOutlet]="menuRef"></ng-template>
        </div>
      </div>

      <div class="menu">
        <div class="navbar-toggler" (click)="isCollapsed = !isCollapsed">
          <span *ngIf="isCollapsed" class="menu-icon material-icons-outlined">
            menu
          </span>
          <span *ngIf="!isCollapsed" class="menu-icon material-icons-outlined">
            close
          </span>
        </div>
      </div>
      <div class="ms-auto me-2">
        <button
          *ngIf="(signedIn$ | async) !== true"
          (click)="login()"
          class="login"
        >
          login
        </button>
        <div class="d-flex flex-column" *ngIf="signedIn$ | async">
          <button (click)="logout()" class="logout">Logout</button>
          <span>{{ (currentUser$ | async)?.userName }}</span>
        </div>
      </div>
    </div>
    <div class="flex-grow-1 menu d-xl-none">
      <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed">
        <ng-template [ngTemplateOutlet]="menuRef"></ng-template>
      </div>
    </div>
  </nav>
</div>
