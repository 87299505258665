import { filter, first, map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserSubscription } from '@metodics/api-client';

import { Facade } from '../+state/facade';

@Injectable()
export class LoadAllNotSubscribedUsersGuard implements CanActivate {
  constructor(private facade: Facade) {}

  canActivate(): Observable<boolean> {
    return this.facade.getNotSubscribedUsers$.pipe(
      map((notSubscribedUsers: UserSubscription[] | undefined) => {
        const loaded = notSubscribedUsers !== undefined;
        if (!loaded) {
          this.facade.loadNotSubscribedUsers();
        }
        return loaded;
      }),
      filter((loaded) => loaded),
      first()
    );
  }
}
