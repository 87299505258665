<div class="d-flex align-items-center isin flex-grow-1 position-relative">
  <input
    [type]="type"
    class="form-control flex-grow-1 web-input"
    [class.dark]="dark"
    [placeholder]="placeholder"
    [formControl]="formControl ? formControl : internalFormControl"
    [class.with-chevron]="withChevron"
    [maxlength]="maxlength"
    (keypress)="keyPress($event)"
    (change)="onChangeEvent($event)"
    [value]="textValue"
  />
  <img
    *ngIf="withChevron"
    class="primary chevron"
    (click)="onClick()"
    src="/assets/images/chevron-right.svg"
  />
</div>
