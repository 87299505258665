<div class="flex-grow-1 d-flex flex-wrap justify-content-center">
  <ng-container *ngIf="type === 'default'">
    <div class="outer" *ngFor="let item of items" (click)="onClick(item)">
      <!-- [class.selected]="item.selected" -->
      <div
        class="inner d-flex flex-column justify-content-center align-items-center text-center"
      >
        <span class="title">{{ item.title }}</span>
        <span class="subtitle" [innerHTML]="item.subtitle"></span>
      </div>
    </div>
  </ng-container>
</div>
