<div class="container-xxl text-center">
  <span class="title"
    >Seleziona il portafoglio al quale aggiugnere il fondo</span
  >
  <div class="mt-4 wallets-container text-center">
    <div class="flex-grow-1 mb-2">
      <button class="btn btn-primary ms-auto mb-5 mt-3" (click)="select('')">
        Nuovo Portafoglio
      </button>
    </div>

    <ng-container *ngFor="let wallet of wallets">
      <div class="flex-grow-1" *ngIf="wallet">
        <button
          class="btn btn-primary ms-auto mb-3"
          (click)="select(wallet.id)"
        >
          {{ wallet.name
          }}<span class="time with-space"
            >creato il {{ wallet.dateTimeDescription }}</span
          >
        </button>
      </div>
    </ng-container>
  </div>
</div>
<div class="d-flex mt-5 text-center">
  <div class="flex-grow-1">
    <button class="btn btn-secondary ms-auto btn-back" (click)="onClose()">
      Torna alla selezione
    </button>
  </div>
</div>
