import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MAIN_FEATURE_KEY, State } from './reducer';

export const stateSelector = createFeatureSelector<State>(MAIN_FEATURE_KEY);

export const macroCategoriesSelector = createSelector(
  stateSelector,
  (s) => s.macroCategories
);

export const fundTotalsSelector = createSelector(
  stateSelector,
  (s) => s.fundTotals
);

export const currentUserSelector = createSelector(
  stateSelector,
  (s) => s.currentUser
);

export const currentUserSubscriptionIsActiveSelector = createSelector(
  stateSelector,
  (s) => s.currentUser?.subscriptionIsActive
);

export const selectedMacroCategoryIdSelector = createSelector(
  stateSelector,
  (s) => s.selectedMacroCategoryId
);

export const selectedMacroCategoryCategoriesSelector = createSelector(
  stateSelector,
  selectedMacroCategoryIdSelector,
  (s, selectedMacroCategoryId) =>
    s.categories.filter((c) => c.macroCategoryId === selectedMacroCategoryId)
);

export const selectedCategoryIdSelector = createSelector(
  stateSelector,
  (s) => s.selectedCategoryId
);

export const selectedCategoryFundsSelector = createSelector(
  stateSelector,
  selectedCategoryIdSelector,
  (s, selectedCategoryId) =>
    s.funds.filter((f) => f.categoryId === selectedCategoryId)
);

export const fundsSelector = createSelector(stateSelector, (s) => s.funds);
export const comparingFundsSettingsSelector = createSelector(
  stateSelector,
  (s) => s.comparingFundsSettings
);

export const categoriesSelector = createSelector(
  stateSelector,
  (s) => s.categories
);
export const draftWalletSelector = createSelector(
  stateSelector,
  (s) => s.draftWallet
);

export const draftWalletNameSelector = createSelector(
  draftWalletSelector,
  (wallet) => wallet.name
);

export const draftWalletDateTimeDescriptionSelector = createSelector(
  draftWalletSelector,
  (wallet) => wallet.dateTimeDescription
);

export const draftWalletFundsIdsSelector = createSelector(
  draftWalletSelector,
  (wallet) => wallet.fundsIds
);

export const selectedYearAndMonthSelector = createSelector(
  stateSelector,
  (s) => s.selectedYearAndMonth
);

export const loggedUserWalletsSelector = createSelector(
  stateSelector,
  (s) => s.loggedUser.wallets
);

//TODO

export const getLoggedUserDevices = createSelector(
  stateSelector,
  (s) => s.loggedUser.devices
);
export const getUsersSubscriptions = createSelector(
  stateSelector,
  (s) => s.usersSubscriptions
);
export const getNotSubscribedUsers = createSelector(
  stateSelector,
  (s) => s.notSubscribedUsers
);
export const getMacroCategoriesIsLoaded = createSelector(
  stateSelector,
  (s) => (s.macroCategories?.length || 0) > 0
);
