import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@metodics/common';

import { Facade } from '../../+state/facade';

@Component({
  selector: 'web-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  signedIn$ = this.authService.signedIn$;
  loggedUser$ = this.facade.currentUser$;
  constructor(
    private authService: AuthService,
    private router: Router,
    private facade: Facade
  ) {}

  goToDebubg() {
    this.router.navigateByUrl('debug');
  }
}
