<button
  class="btn action flex-grow-1 d-flex"
  [class.btn-primary]="styleType === 'primary'"
  [class.btn-primary-inverted-variant-dark]="
    styleType === 'primary-inverted-dark'
  "
  [class.btn-primary-inverted-variant]="styleType === 'primary-inverted'"
  [disabled]="disabled"
  (click)="onClick()"
>
  <span class="description">{{ label }}</span
  ><img
    *ngIf="withChevron"
    class="chevron ms-auto"
    [class.primary-inverted]="styleType === 'primary'"
    [class.primary]="
      styleType === 'primary-inverted' || styleType === 'primary-inverted-dark'
    "
    [class.rotated]="withChevronAnimation && styleType === 'primary'"
    [src]="
      styleType === 'primary'
        ? 'assets/images/chevron-right-primary.svg'
        : 'assets/images/chevron-right.svg'
    "
  />
</button>
