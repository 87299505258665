import { filter, first, map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { User } from '@metodics/api-client';

import { Facade } from '../+state/facade';

@Injectable()
export class LoggedUserGuard implements CanActivate {
  constructor(private facade: Facade, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.facade.currentUser$.pipe(
      map((loggedUser: User | undefined) => {
        if (!loggedUser) {
          this.facade.loadCurrentUser();
        }
        return loggedUser !== null;
      }),
      filter((loggedUser) => !!loggedUser),
      first()
    );
  }
  // canActivate(): Observable<boolean> {
  //   return this.authService.accessToken$.pipe(
  //     map((token: string | null) => {
  //       if (token === null) {
  //         this.router.navigateByUrl('');
  //         return false;
  //       }
  //       return true;
  //     })
  //   );
  // }
}
