import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LoaderEffects } from './+state/ngrx-loader.effects';
import * as fromLoader from './+state/ngrx-loader.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromLoader.LOADER_FEATURE_KEY, fromLoader.reducer),
    EffectsModule.forFeature([LoaderEffects]),
  ],
})
export class NgrxLoaderModule {}
