import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MAIN_FEATURE_KEY, reducer } from './+state/reducer';
import { LayoutComponent } from './containers/layout/layout.component';
import { LoginGuard } from './guards/login.guard';

const appRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./lazy/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'confronta-fondi',
        loadChildren: () =>
          import('./lazy/confronta-fondi/confronta-fondi.module').then(
            (m) => m.ConfrontaFondiModule
          ),
      },
      {
        path: 'rating-fondi-azionari',
        loadChildren: () =>
          import('./lazy/crea-portafoglio/crea-portafoglio.module').then(
            (m) => m.CreaPortafoglioModule
          ),
      },
      {
        path: 'nostro-rating',
        loadChildren: () =>
          import('./lazy/nostro-rating/nostro-rating.module').then(
            (m) => m.NostroRatingModule
          ),
      },
      {
        path: 'educazione-finanziaria',
        loadChildren: () =>
          import(
            './lazy/educazione-finanziaria/educazione-finanziaria.module'
          ).then((m) => m.EducazioneFinanziariaModule),
      },
      {
        path: 'debug',
        canActivate: [LoginGuard],
        loadChildren: () =>
          import('./lazy/debug-page/debug.module').then((m) => m.DebugModule),
      },
      {
        path: 'miei-portafogli',
        loadChildren: () =>
          import('./lazy/miei-portafogli/miei-portafogli.module').then(
            (m) => m.MieiPortafogliModule
          ),
      },
      {
        path: 'miei-dati',
        loadChildren: () =>
          import('./lazy/miei-dati/miei-dati.module').then(
            (m) => m.MieiDatiModule
          ),
      },
      {
        path: 'miei-dispositivi',
        loadChildren: () =>
          import('./lazy/miei-dispositivi/miei-dispositivi.module').then(
            (m) => m.MieiDispositiviModule
          ),
      },
      {
        path: 'gestione-abbonamento',
        loadChildren: () =>
          import(
            './lazy/gestione-abbonamento/gestione-abbonamento.module'
          ).then((m) => m.GestioneAbbonamentoModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
    StoreModule.forFeature(MAIN_FEATURE_KEY, reducer),
    EffectsModule.forFeature([]),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
