import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map } from 'rxjs';

import { Injectable } from '@angular/core';
import { ToastService } from '@metodics/ui-kit';

@Injectable()
export class AuthService {
  constructor(
    protected toastService: ToastService,
    private oidcSecurityService: OidcSecurityService
  ) {}

  async login(asPopup = false) {
    if (asPopup) {
      const somePopupOptions = {
        width: 800,
        height: 500,
        left: 50,
        top: 50,
      };
      const authOptions = {};
      this.oidcSecurityService
        .authorizeWithPopUp(authOptions, somePopupOptions)
        .subscribe(
          ({ isAuthenticated, userData, accessToken, errorMessage }) => {
            /* use data */
          }
        );
    } else {
      this.write('redirect', window.location.pathname);
      this.oidcSecurityService.authorize();
    }
  }

  get accessToken$() {
    return this.oidcSecurityService.getAccessToken();
  }

  get signedIn$() {
    return this.oidcSecurityService.isAuthenticated$.pipe(
      map((i) => {
        console.log('signedIn$', i.isAuthenticated);
        return i.isAuthenticated;
      })
    );
  }
  logout() {
    // logoff only locally otherwise the page is reloaded
    this.oidcSecurityService
      .logoff()
      //.logoffLocal()
      .subscribe((result) => {
        console.log('logoff:', result);
      });
  }

  private write(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
