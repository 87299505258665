import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiClientConfig } from '../api-client-config';
import { UserDeviceDto } from '../dto/user-device.dto';
import { BaseCRUDApiClient } from './base-crud.api-client';

@Injectable()
export class UserDeviceApiClient extends BaseCRUDApiClient<UserDeviceDto> {
  baseUrl = this.config.apiEndpoint + '/api/user-device';

  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }

  getForCurrentUser() {
    return this.http.get<UserDeviceDto[]>(`${this.baseUrl}/current-user`);
  }
}
