import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface MacroCategoryListItem {
  id: string;
  title: string;
  totalCategories: number;
  totalFunds: number;
}

@Component({
  selector: 'web-macro-category-list',
  templateUrl: './macro-category-list.component.html',
  styleUrls: ['./macro-category-list.component.scss'],
})
export class MacroCategoryListComponent {
  @Input()
  items?: MacroCategoryListItem[] = undefined;
  @Input()
  selectedItemId?: string;
  @Input()
  showTitle = false;

  @Output()
  selected = new EventEmitter<string>();

  onClick(item: MacroCategoryListItem) {
    if (this.selected) {
      this.selected.emit(item.id);
    }
  }

  isSelected(item: MacroCategoryListItem) {
    return this.selectedItemId == item.id;
  }

  isCliccable() {
    return this.selected.observed;
  }
}
