import { filter, first, map, Observable, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { User } from '@metodics/api-client';
import { SubscriptionService } from '@metodics/common';

import { Facade } from '../+state/facade';
import { ToastService } from '@metodics/ui-kit';

@Injectable()
export class SubscribedLoggedUserGuard implements CanActivate {
  constructor(
    private facade: Facade,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService
  ) {}

  canActivate(): Observable<boolean> {
    return this.facade.currentUser$.pipe(
      tap((loggedUser: User | undefined) => {
        if (!loggedUser) {
          this.facade.loadCurrentUser();
        }
      }),
      filter((loggedUser) => !!loggedUser),
      map((loggedUser) => {
        const subscriptionIsActive = loggedUser?.subscriptionIsActive || false;
        if (!subscriptionIsActive) {
          this.subscriptionService.showModal(undefined, loggedUser, () => {
            this.facade.loadCurrentUser();
          });
        }
        return subscriptionIsActive;
      }),
      first()
    );
  }
}
