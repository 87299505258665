// IMPORTANT Autogenerated file
import { Injectable } from '@angular/core';
import { Fund, UserSubscription } from '@metodics/api-client';
import { select, Store } from '@ngrx/store';

import {
  addUserSubscription,
  createLocalWallet,
  createWalletAction,
  deleteUserDevice,
  deleteUserSubscription,
  deleteWalletAction,
  loadCategoryAction,
  loadCurrentUserAction,
  searchAndAddFundToCompareListAction,
  loadFundTotalsAction,
  loadLoggedUserDevicesAction,
  loadMacroCategoriesAction,
  loadNotSubscribedUsers,
  loadUsersSubscriptions,
  selectCategoryIdAction,
  selectMacroCategoryIdAction,
  udpateDraftWalletNameAction,
  showSelectedWalletCategoryFunds,
  addFundToCompareListAction,
  updateComparedFundModeAction,
  updateComparedFundCategoryReportPanelExpandedStatusAction,
  updateComparedFundDetailPanelExpandedStatusAction,
  addFundToDraftWalletAction,
  removeFundFromCompareListAction,
  removeFundFromDraftWalletAction,
  selectYearAndMonthAction,
  removeFundFromWalletAction,
  loadCurrentUserWalletsAction,
  addFundToWalletAction,
  updateWalletNameAction,
} from './actions';
import * as selectors from './selectors';

@Injectable()
export class Facade {
  macroCategories$ = this.store.pipe(select(selectors.macroCategoriesSelector));
  fundTotals$ = this.store.pipe(select(selectors.fundTotalsSelector));

  currentUser$ = this.store.pipe(select(selectors.currentUserSelector));

  currentUserSubscriptionIsActive$ = this.store.pipe(
    select(selectors.currentUserSubscriptionIsActiveSelector)
  );

  selectedMacroCategoryId$ = this.store.pipe(
    select(selectors.selectedMacroCategoryIdSelector)
  );
  selectedMacroCategoryCategories$ = this.store.pipe(
    select(selectors.selectedMacroCategoryCategoriesSelector)
  );

  selectedCategoryId$ = this.store.pipe(
    select(selectors.selectedCategoryIdSelector)
  );

  selectedCategoryFunds$ = this.store.pipe(
    select(selectors.selectedCategoryFundsSelector)
  );

  funds$ = this.store.pipe(select(selectors.fundsSelector));
  comparingFundsSettings$ = this.store.pipe(
    select(selectors.comparingFundsSettingsSelector)
  );

  categories$ = this.store.pipe(select(selectors.categoriesSelector));

  draftWalletName$ = this.store.pipe(select(selectors.draftWalletNameSelector));

  draftWalletDateTimeDescription$ = this.store.pipe(
    select(selectors.draftWalletDateTimeDescriptionSelector)
  );

  draftWalletFundsIds$ = this.store.pipe(
    select(selectors.draftWalletFundsIdsSelector)
  );

  loggedUserWallets$ = this.store.pipe(
    select(selectors.loggedUserWalletsSelector)
  );

  //TODO

  getLoggedUserDevices$ = this.store.pipe(
    select(selectors.getLoggedUserDevices)
  );
  getUsersSubscriptions$ = this.store.pipe(
    select(selectors.getUsersSubscriptions)
  );
  getNotSubscribedUsers$ = this.store.pipe(
    select(selectors.getNotSubscribedUsers)
  );

  getMacroCategoriesIsLoaded$ = this.store.pipe(
    select(selectors.getMacroCategoriesIsLoaded)
  );

  selectedYearAndMonth$ = this.store.pipe(
    select(selectors.selectedYearAndMonthSelector)
  );

  constructor(private store: Store) {}

  loadMacroCategories() {
    this.store.dispatch(loadMacroCategoriesAction());
  }

  loadFundTotals() {
    this.store.dispatch(loadFundTotalsAction());
  }

  selectMacroCategoryId(macroCategoryId: string) {
    this.store.dispatch(selectMacroCategoryIdAction({ macroCategoryId }));
  }

  selectCategoryId(categoryId: string) {
    this.store.dispatch(selectCategoryIdAction({ categoryId }));
  }
  searchAndAddFundToCompareList(
    isin: string,
    navigateToCompare: boolean = false
  ) {
    this.store.dispatch(
      searchAndAddFundToCompareListAction({
        isin,
        navigateToCompare: navigateToCompare,
      })
    );
  }
  addFundToCompareList(fund: Fund, navigateToCompare: boolean) {
    this.store.dispatch(
      addFundToCompareListAction({ fund, navigateToCompare: navigateToCompare })
    );
  }

  removeFundFromCompareList(fundId: string) {
    this.store.dispatch(removeFundFromCompareListAction({ fundId }));
  }

  updateComparedFundMode(
    fundId: string,
    mode: 'FUND_DETAILS' | 'CATEGORY_REPORT'
  ) {
    this.store.dispatch(updateComparedFundModeAction({ fundId, mode }));
  }
  updateComparedFundCategoryReportPanelExpandedStatus(
    fundId: string,
    isExpanded: boolean
  ) {
    this.store.dispatch(
      updateComparedFundCategoryReportPanelExpandedStatusAction({
        fundId,
        isExpanded,
      })
    );
  }

  updateComparedFundDetailPanelExpandedStatus(
    fundId: string,
    isExpanded: boolean
  ) {
    this.store.dispatch(
      updateComparedFundDetailPanelExpandedStatusAction({
        fundId,
        isExpanded,
      })
    );
  }

  addFundToDraftWallet(fundId: string) {
    this.store.dispatch(addFundToDraftWalletAction({ fundId }));
  }

  removeFundFromDraftWallet(fundId: string) {
    this.store.dispatch(removeFundFromDraftWalletAction({ fundId }));
  }

  selectYearAndMonth(year: number, month: number) {
    this.store.dispatch(selectYearAndMonthAction({ year, month }));
  }

  loadCurrentUserWallets() {
    this.store.dispatch(loadCurrentUserWalletsAction());
  }

  deleteWallet(id: string) {
    this.store.dispatch(deleteWalletAction({ id }));
  }
  createWallet() {
    this.store.dispatch(createWalletAction());
  }

  loadCategory(id: string) {
    this.store.dispatch(loadCategoryAction({ id }));
  }

  removeFundFromWallet(walletFundId: string) {
    this.store.dispatch(removeFundFromWalletAction({ walletFundId }));
  }

  addFundToWallet(walletId: string, fundId: string) {
    this.store.dispatch(addFundToWalletAction({ walletId, fundId }));
  }

  updateWalletName(walletId: string, name: string) {
    this.store.dispatch(updateWalletNameAction({ walletId, name }));
  }

  //TODO
  loadCurrentUser() {
    this.store.dispatch(loadCurrentUserAction());
  }
  showSelectedWalletCategoryFunds(show: boolean) {
    this.store.dispatch(showSelectedWalletCategoryFunds({ show }));
  }
  loadLoggedUserDevices() {
    this.store.dispatch(loadLoggedUserDevicesAction());
  }
  deleteUserDevice(id: string) {
    this.store.dispatch(deleteUserDevice({ id }));
  }
  loadUsersSubscriptions() {
    this.store.dispatch(loadUsersSubscriptions());
  }
  deleteUserSubscription(id: string) {
    this.store.dispatch(deleteUserSubscription({ id }));
  }
  loadNotSubscribedUsers() {
    this.store.dispatch(loadNotSubscribedUsers());
  }
  addUserSubscription(userSubscription: Partial<UserSubscription>) {
    this.store.dispatch(addUserSubscription({ userSubscription }));
  }
  setWalletName(name: string) {
    this.store.dispatch(udpateDraftWalletNameAction({ name }));
  }
  createLocalWallet() {
    this.store.dispatch(createLocalWallet());
  }
}
