import {
  catchError,
  concatMap,
  EMPTY,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  CategoryApiClient,
  CategoryDto,
  FundApiClient,
  FundDto,
  FundTotals,
  MacroCategoryApiClient,
  MacroCategoryDto,
  User,
  UserApiClient,
  UserDeviceApiClient,
  UserDeviceDto,
  UserSubscriptionApiClient,
  UserSubscriptionDto,
  WalletApiClient,
  WalletDto,
} from '@metodics/api-client';
import { AuthService } from '@metodics/common';
import { NgrxLoaderFacade } from '@metodics/ngrx-loader';
import { ToastService } from '@metodics/ui-kit';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, props, Store } from '@ngrx/store';

import * as MainActions from './actions';
import { Facade } from './facade';

const genericErrorMessage = 'Si è verificato un errore';
@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private facade: Facade,
    private router: Router,
    private loaderFacade: NgrxLoaderFacade,
    private fundApiClient: FundApiClient,
    protected toastService: ToastService,
    private categoryApiClient: CategoryApiClient,
    private userApiClient: UserApiClient,
    private walletApiClient: WalletApiClient,
    private userDeviceApiClient: UserDeviceApiClient,
    private userSubscriptionApiClient: UserSubscriptionApiClient,
    private authService: AuthService,
    private macroCategoryApiClient: MacroCategoryApiClient
  ) {}

  loadMacroCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.loadMacroCategoriesAction),
      switchMap((action) => {
        this.loaderFacade.showLoader(action);
        return this.macroCategoryApiClient.getAll().pipe(
          map((categories: MacroCategoryDto[]) => {
            this.loaderFacade.hideLoader(action);

            return MainActions.loadMacroCategoriesSuccessAction({
              macroCategories: categories,
            });
          }),
          catchError((error) => {
            this.loaderFacade.hideLoader(action);
            return of(
              MainActions.loadMacroCategoriesFailureAction({
                error: error.statusText || error,
              })
            );
          })
        );
      })
    )
  );

  loadMacroCategoriesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.loadMacroCategoriesFailureAction),
        tap((action) => {
          this.toastService.errorFeedback(genericErrorMessage, action.error);
        })
      ),
    { dispatch: false }
  );

  loadFundTotals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.loadFundTotalsAction),
      switchMap((action) => {
        this.loaderFacade.showLoader(action);
        return this.fundApiClient.getTotals().pipe(
          map((fundTotals: FundTotals) => {
            this.loaderFacade.hideLoader(action);
            return MainActions.loadFundTotalsSuccessAction({
              fundTotals,
            });
          }),
          catchError((error) => {
            this.loaderFacade.hideLoader(action);
            return of(
              MainActions.loadFundTotalsFailureAction({
                error: error.statusText || error,
              })
            );
          })
        );
      })
    )
  );
  loadFundTotalsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.loadFundTotalsFailureAction),
        tap((action) => {
          this.toastService.errorFeedback(genericErrorMessage, action.error);
        })
      ),
    { dispatch: false }
  );

  loadCategoriesBySelectedMacroCategoryId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.selectMacroCategoryIdAction),
      switchMap((action) => {
        this.loaderFacade.showLoader(action);
        return this.categoryApiClient
          .getByMacroCategory(action.macroCategoryId)
          .pipe(
            map((categories: CategoryDto[]) => {
              this.loaderFacade.hideLoader(action);
              return MainActions.loadCategoriesBySelectedMacroCategoryIdSuccessAction(
                {
                  categories: categories,
                }
              );
            }),
            catchError((error) => {
              this.loaderFacade.hideLoader(action);
              return of(
                MainActions.loadCategoriesBySelectedMacroCategoryIdFailureAction(
                  {
                    error: error.statusText || error,
                  }
                )
              );
            })
          );
      })
    )
  );
  loadCategoriesByMacroCategoryIdFailureAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          MainActions.loadCategoriesBySelectedMacroCategoryIdFailureAction
        ),
        tap((action) => {
          this.toastService.errorFeedback(genericErrorMessage, action.error);
        })
      ),
    { dispatch: false }
  );

  loadCategoryFunds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.selectCategoryIdAction),
      concatMap((a) => of(a).pipe(withLatestFrom(this.facade.categories$))),
      concatMap((a) => of(a).pipe(withLatestFrom(this.facade.funds$))),
      switchMap(([[action, categories], funds]) => {
        this.loaderFacade.showLoader(action);
        const category = categories.find((c) => c.id == action.categoryId);

        if (category == null) {
          this.loaderFacade.hideLoader(action);
          this.facade.loadCategory(action.categoryId);
        }

        const fundsPerCategory = funds.filter(
          (f) => f.categoryId == action.categoryId
        );

        if (fundsPerCategory.length == category?.lastTotalFunds) {
          this.loaderFacade.hideLoader(action);
          return EMPTY;
        } else {
          return this.fundApiClient.getByCategory(action.categoryId).pipe(
            map((funds: FundDto[]) => {
              this.loaderFacade.hideLoader(action);
              return MainActions.loadFundsBySelectedCategorySuccessAction({
                funds,
              });
            }),
            catchError((error) => {
              this.loaderFacade.hideLoader(action);
              return of(
                MainActions.loadFundsBySelectedCategoryFailureAction({
                  error: error.statusText || error,
                })
              );
            })
          );
        }
      })
    )
  );
  loadFundsBySelectedCategoryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.loadFundsBySelectedCategoryFailureAction),
        tap((action) => {
          this.toastService.errorFeedback(genericErrorMessage, action.error);
        })
      ),
    { dispatch: false }
  );

  addFundToCompareListAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.addFundToCompareListAction),
        tap((action) => {
          if (action.navigateToCompare) {
            this.router.navigateByUrl('/confronta-fondi');
          }
        })
      ),
    { dispatch: false }
  );

  searchAndAddFundToCompareList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.searchAndAddFundToCompareListAction),
      concatMap((a) =>
        of(a).pipe(withLatestFrom(this.facade.comparingFundsSettings$))
      ),
      concatMap((a) => of(a).pipe(withLatestFrom(this.facade.funds$))),
      concatMap((a) => of(a).pipe(withLatestFrom(this.facade.categories$))),
      concatMap((a) =>
        of(a).pipe(withLatestFrom(this.facade.macroCategories$))
      ),

      switchMap(
        ([
          [[[action, comparingFundsSettings], funds], categories],
          macroCategories,
        ]) => {
          this.loaderFacade.showLoader(action);

          if (
            comparingFundsSettings.find((x) => x.fundIsin == action.isin) !=
            undefined
          ) {
            this.loaderFacade.hideLoader(action);
            this.toastService.warnNotification(
              'Il fondo con isin ' +
                action.isin +
                ' è già presente in "Confronta i tuoi fondi"'
            );
            return EMPTY;
          }

          //TODO MARCOC: ISIN non è univoco
          const fund = funds.find((f) => f.isin === action.isin);
          if (fund != undefined) {
            const category = categories.find((c) => c.id == fund.categoryId);

            if (category == null) {
              this.facade.loadCategory(fund.categoryId);
              this.facade.loadMacroCategories();
            } else {
              const macroCategory = macroCategories.find(
                (c) => c.id == category.macroCategoryId
              );

              if (macroCategory == null) {
                this.facade.loadMacroCategories();
              }
            }

            this.loaderFacade.hideLoader(action);
            this.facade.addFundToCompareList(fund, action.navigateToCompare);

            let message: string | undefined;
            // if (this.router.routerState.snapshot.url != '/confronta-fondi') {
            //   message =
            //     'Clicca qui per andare alla sezione "Confronta i tuoi fondi"';
            // }
            const notification = this.toastService.successFeedback(
              'Fondo aggiunto alla lista Confronta i tuoi fondi.',
              message
            );
            if (message != undefined) {
              notification.onTap.subscribe(() => {
                this.router.navigateByUrl('/confronta-fondi');
              });
            }

            return EMPTY;
          } else {
            return this.fundApiClient.get(action.isin).pipe(
              map((fund: FundDto) => {
                this.loaderFacade.hideLoader(action);
                if (fund) {
                  const category = categories.find(
                    (c) => c.id == fund.categoryId
                  );

                  if (category == null) {
                    this.facade.loadCategory(fund.categoryId);
                    this.facade.loadMacroCategories();
                  } else {
                    const macroCategory = macroCategories.find(
                      (c) => c.id == category.macroCategoryId
                    );

                    if (macroCategory == null) {
                      this.facade.loadMacroCategories();
                    }
                  }

                  this.facade.addFundToCompareList(
                    fund,
                    action.navigateToCompare
                  );

                  let message: string | undefined;
                  if (
                    this.router.routerState.snapshot.url != '/confronta-fondi'
                  ) {
                    message =
                      'Clicca qui per andare alla sezione "Confronta i tuoi fondi"';
                  }
                  const notification = this.toastService.successFeedback(
                    'Fondo aggiunto alla lista Confronta i tuoi fondi.',
                    message
                  );
                  if (message != undefined) {
                    notification.onTap.subscribe(() => {
                      this.router.navigateByUrl('/confronta-fondi');
                    });
                  }

                  // this.router.navigateByUrl('confronta-fondi#isins');
                  // this.facade.loadCategory(fund.categoryId);
                  return MainActions.loadFundSuccessAction({
                    fund,
                  });
                } else {
                  return MainActions.loadFundFailureAction({
                    error: 'Isin inesistente',
                  });
                }
              }),
              catchError((error) => {
                this.loaderFacade.hideLoader(action);
                return of(
                  MainActions.loadFundFailureAction({
                    error: error.error.error || error.statusText || error,
                  })
                );
              })
            );
          }
        }
      )
    )
  );
  loadFundFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.loadFundFailureAction),
        tap((action) => {
          this.toastService.errorFeedback(genericErrorMessage, action.error);
        })
      ),
    { dispatch: false }
  );

  loadCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.loadCategoryAction),
      switchMap((action) => {
        this.loaderFacade.showLoader(action);
        return this.categoryApiClient.get(action.id).pipe(
          map((category: CategoryDto) => {
            this.loaderFacade.hideLoader(action);
            if (category) {
              return MainActions.loadCategorySuccessAction({
                category: category,
              });
            } else
              return MainActions.loadCategoryFailureAction({
                error: 'Categoria inesistente',
              });
          }),
          catchError((error) => {
            this.loaderFacade.hideLoader(action);
            return of(
              MainActions.loadCategoryFailureAction({
                error: error.statusText || error,
              })
            );
          })
        );
      })
    )
  );

  loadCategoryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.loadCategoryFailureAction),
        tap((action) => {
          this.toastService.errorFeedback(genericErrorMessage, action.error);
        })
      ),
    { dispatch: false }
  );

  loadFundCategoryMacroCategory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.addFundToDraftWalletAction),
        concatMap((a) => of(a).pipe(withLatestFrom(this.facade.funds$))),
        concatMap((a) => of(a).pipe(withLatestFrom(this.facade.categories$))),
        concatMap((a) =>
          of(a).pipe(withLatestFrom(this.facade.macroCategories$))
        ),
        switchMap(([[[action, funds], categories], macroCategories]) => {
          const fund = funds.find((c) => c.id == action.fundId);
          if (fund != null) {
            const category = categories.find((c) => c.id == fund.categoryId);

            if (category == null) {
              this.facade.loadCategory(fund.categoryId);
              this.facade.loadMacroCategories();
            } else {
              const macroCategory = macroCategories.find(
                (c) => c.id == category.macroCategoryId
              );

              if (macroCategory == null) {
                this.facade.loadMacroCategories();
              }
            }
          }

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  createWallet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.createWalletAction),
      concatMap((a) =>
        of(a).pipe(
          withLatestFrom(
            this.facade.currentUser$,
            this.facade.draftWalletName$,
            this.facade.draftWalletDateTimeDescription$,
            this.facade.draftWalletFundsIds$
          )
        )
      ),
      switchMap(
        ([
          action,
          loggedUser,
          walletName,
          walletDateTimeDescription,
          draftWalletFundsIds,
        ]) => {
          this.loaderFacade.showLoader(action);
          return this.walletApiClient
            .create({
              userId: loggedUser?.id || '',
              name: walletName,
              dateTimeDescription: walletDateTimeDescription,
              funds: draftWalletFundsIds.map((x) => ({ fundId: x })),
            })
            .pipe(
              map((wallet: WalletDto) => {
                this.loaderFacade.hideLoader(action);
                this.toastService.successFeedback(
                  'Portafoglio creato con successo'
                );
                return MainActions.createWalletSuccessAction({
                  wallet,
                });
              }),
              catchError((error) => {
                //httperrorresponse
                this.loaderFacade.hideLoader(action);
                return of(
                  MainActions.createWalletFailureAction({
                    error: error.error?.error || error.statusText || error,
                  })
                );
              })
            );
        }
      )
    )
  );

  createWalletFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.createWalletFailureAction),
        tap((action) => {
          this.toastService.errorFeedback(
            'Si è verificato un errore durante la creazione del portafoglio',
            action.error
          );
        })
      ),
    { dispatch: false }
  );

  selectYearAndMonth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.selectYearAndMonthAction),
      concatMap((a) => of(a).pipe(withLatestFrom(this.facade.currentUser$))),
      map(([action, currentUser]) => {
        if (currentUser != undefined && currentUser.subscriptionIsActive) {
          return MainActions.loadCurrentUserWalletsAction();
        } else {
          return createAction(`No Action`)();
        }
      })
    )
  );

  loadCurrentUserWallets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.loadCurrentUserWalletsAction),
      concatMap((a) =>
        of(a).pipe(withLatestFrom(this.facade.selectedYearAndMonth$))
      ),
      switchMap(([action, selectedYearAndMonth]) => {
        return this.walletApiClient
          .getForCurrentUser(
            selectedYearAndMonth.year,
            selectedYearAndMonth.month
          )
          .pipe(
            map((wallets: WalletDto[]) => {
              this.loaderFacade.hideLoader(action);
              return MainActions.loadLoggedUserWalletsSuccessAction({
                wallets,
              });
            }),
            catchError((error) => {
              //httperrorresponse
              this.loaderFacade.hideLoader(action);
              return of(
                MainActions.loadLoggedUserWalletsFailureAction({
                  error: error.error?.error || error.statusText || error,
                })
              );
            })
          );
      })
    )
  );
  loadCurrentUserWalletsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.loadLoggedUserWalletsFailureAction),
        tap((action) => {
          this.toastService.errorFeedback(genericErrorMessage, action.error);
        })
      ),
    { dispatch: false }
  );

  deleteWallet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.deleteWalletAction),
      switchMap((action) => {
        return this.walletApiClient.delete(action.id).pipe(
          map(() => {
            this.loaderFacade.hideLoader(action);
            this.toastService.successFeedback(
              'Portafoglio eliminato con successo'
            );
            return MainActions.deleteWalletSuccessAction({ id: action.id });
          }),
          catchError((error) => {
            //httperrorresponse
            this.loaderFacade.hideLoader(action);
            return of(
              MainActions.deleteWalletFailureAction({
                error: error.error?.error || error.statusText || error,
              })
            );
          })
        );
      })
    )
  );

  deleteWalletFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.deleteWalletFailureAction),
        tap((action) => {
          this.toastService.errorFeedback(
            "Si è verificato un errore durante l'eliminazione",
            action.error
          );
        })
      ),
    { dispatch: false }
  );

  removeFundFromWallet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.removeFundFromWalletAction),
      switchMap((action) => {
        return this.walletApiClient
          .deleteFundFromWallet(action.walletFundId)
          .pipe(
            map(() => {
              this.loaderFacade.hideLoader(action);
              this.toastService.successFeedback(
                'Portafoglio eliminato con successo'
              );
              this.facade.loadCurrentUserWallets();
              return MainActions.removeFundFromWalletSuccessAction({
                walletFundId: action.walletFundId,
              });
            }),
            catchError((error) => {
              //httperrorresponse
              this.loaderFacade.hideLoader(action);
              return of(
                MainActions.removeFundFromWalletFailureAction({
                  error: error.error?.error || error.statusText || error,
                })
              );
            })
          );
      })
    )
  );

  removeFundFromWalletFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.removeFundFromWalletFailureAction),
        tap((action) => {
          this.toastService.errorFeedback(
            "Si è verificato un errore durante l'eliminazione",
            action.error
          );
        })
      ),
    { dispatch: false }
  );

  addFundToWallet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.addFundToWalletAction),
      switchMap((action) => {
        return this.walletApiClient
          .addFundToWallet(action.walletId, action.fundId)
          .pipe(
            map(() => {
              this.loaderFacade.hideLoader(action);
              this.toastService.successFeedback('Fondo aggiunto con successo');
              this.facade.loadCurrentUserWallets();
              return MainActions.addFundToWalletSuccessAction();
            }),
            catchError((error) => {
              //httperrorresponse
              this.loaderFacade.hideLoader(action);
              return of(
                MainActions.addFundToWalletFailureAction({
                  error: error.error?.error || error.statusText || error,
                })
              );
            })
          );
      })
    )
  );

  addFundToWalletFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.addFundToWalletFailureAction),
        tap((action) => {
          this.toastService.errorFeedback(
            "Si è verificato un errore durante l'operazione",
            action.error
          );
        })
      ),
    { dispatch: false }
  );

  updateWalletName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.updateWalletNameAction),
      switchMap((action) => {
        return this.walletApiClient
          .udpateWalletName(action.walletId, action.name)
          .pipe(
            map(() => {
              this.loaderFacade.hideLoader(action);
              this.toastService.successFeedback(
                'Nome portafoglio aggiornato con successo'
              );
              this.facade.loadCurrentUserWallets();
              return MainActions.updateWalletNameSuccessAction();
            }),
            catchError((error) => {
              this.loaderFacade.hideLoader(action);
              return of(
                MainActions.updateWalletNameFailureAction({
                  error: error.error?.error || error.statusText || error,
                })
              );
            })
          );
      })
    )
  );

  updateWalletNameFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.updateWalletNameFailureAction),
        tap((action) => {
          this.toastService.errorFeedback(
            "Si è verificato un errore durante l'operazione",
            action.error
          );
        })
      ),
    { dispatch: false }
  );

  //TODO

  loadCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.loadCurrentUserAction),
      switchMap((action) => {
        this.loaderFacade.showLoader(action);
        return this.userApiClient.getCurrentUser().pipe(
          map((loggedUser: User) => {
            this.loaderFacade.hideLoader(action);

            this.facade.loadLoggedUserDevices();

            if (loggedUser.deviceLimitExceeded) {
              const isDevicesLimitExceeded = loggedUser.deviceLimitExceeded;
              if (isDevicesLimitExceeded === true) {
                this.toastService.errorFeedback(
                  'Attenzione',
                  'Hai superato il numero massimo di dispositivi consentiti. Elimina un dispositivo dalla sezione "I miei dispositivi"',
                  false
                );
                this.router.navigateByUrl('miei-dispositivi');
              }
            }

            return MainActions.loadCurrentUserSuccessAction({
              currentUser: loggedUser,
            });
          }),
          catchError((error) => {
            //httperrorresponse
            this.loaderFacade.hideLoader(action);

            return of(
              MainActions.loadCurrentUserFailureAction({
                error: error.error?.error || error.statusText || error,
              })
            );
          })
        );
      })
    )
  );
  loadCurrentUserFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.loadCurrentUserFailureAction),
        tap((action) => {
          this.toastService.errorFeedback(genericErrorMessage, action.error);
        })
      ),
    { dispatch: false }
  );

  loadCurrentUserDevices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.loadLoggedUserDevicesAction),
      switchMap((action) => {
        return this.userDeviceApiClient.getForCurrentUser().pipe(
          map((devices: UserDeviceDto[]) => {
            this.loaderFacade.hideLoader(action);
            return MainActions.loadLoggedUserDevicesSuccess({ devices });
          }),
          catchError((error) => {
            //httperrorresponse
            this.loaderFacade.hideLoader(action);
            return of(
              MainActions.loadLoggedUserDevicesFailure({
                error: error.error?.error || error.statusText || error,
              })
            );
          })
        );
      })
    )
  );
  loadCurrentUserDevicesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.loadLoggedUserDevicesFailure),
        tap((action) => {
          this.toastService.errorFeedback(genericErrorMessage, action.error);
        })
      ),
    { dispatch: false }
  );
  deleteUserDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.deleteUserDevice),
      switchMap((action) => {
        return this.userDeviceApiClient.delete(action.id).pipe(
          map(() => {
            this.loaderFacade.hideLoader(action);
            this.toastService.successFeedback(
              'Dispositivo eliminato con successo'
            );
            this.facade.loadCurrentUser();

            return MainActions.deleteUserDeviceSuccess({ id: action.id });
          }),
          catchError((error) => {
            //httperrorresponse
            this.loaderFacade.hideLoader(action);
            return of(
              MainActions.deleteUserDeviceFailure({
                error: error.error?.error || error.statusText || error,
              })
            );
          })
        );
      })
    )
  );
  deleteUserDeviceFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.deleteUserDeviceFailure),
        tap((action) => {
          if (action.error === 'DELETE_DEVICE_30DAYS_POLICY') {
            this.toastService.warnNotification(
              'Attenzione',
              "Non puoi eliminare il dispositivo. Devi aspettare 30 giorni, dall'ultima registrazione di un dispostivo.",
              false
            );
          } else {
            this.toastService.errorFeedback(
              "Si è verificato un errore durante l'eliminazione",
              action.error
            );
          }
        })
      ),
    { dispatch: false }
  );
  loadUsersSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.loadUsersSubscriptions),
      switchMap((action) => {
        this.loaderFacade.showLoader(action);
        return this.userSubscriptionApiClient.getAll().pipe(
          map((usersSubscriptions: UserSubscriptionDto[]) => {
            this.loaderFacade.hideLoader(action);
            return MainActions.loadUsersSubscriptionsSuccess({
              usersSubscriptions,
            });
          }),
          catchError((error) => {
            this.loaderFacade.hideLoader(action);
            return of(
              MainActions.loadUsersSubscriptionsFailure({
                error: error.statusText || error,
              })
            );
          })
        );
      })
    )
  );
  loadUsersSubscriptionsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.loadUsersSubscriptionsFailure),
        tap((action) => {
          this.toastService.errorFeedback(genericErrorMessage, action.error);
        })
      ),
    { dispatch: false }
  );
  deleteUserSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.deleteUserSubscription),
      switchMap((action) => {
        return this.userSubscriptionApiClient.delete(action.id).pipe(
          map(() => {
            this.loaderFacade.hideLoader(action);
            this.facade.loadNotSubscribedUsers();
            this.toastService.successFeedback(
              'Sottoscrizione eliminata con successo'
            );
            return MainActions.deleteUserSubscriptionSuccess({ id: action.id });
          }),
          catchError((error) => {
            //httperrorresponse
            this.loaderFacade.hideLoader(action);
            return of(
              MainActions.deleteUserSubscriptionFailure({
                error: error.error?.error || error.statusText || error,
              })
            );
          })
        );
      })
    )
  );
  deleteUserSubscriptionFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.deleteUserSubscriptionFailure),
        tap((action) => {
          this.toastService.errorFeedback(
            "Si è verificato un errore durante l'eliminazione",
            action.error
          );
        })
      ),
    { dispatch: false }
  );
  loadNotSubscribedUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.loadNotSubscribedUsers),
      switchMap((action) => {
        this.loaderFacade.showLoader(action);
        return this.userSubscriptionApiClient.getNotSubscribed().pipe(
          map((notSubscribedUsers: UserSubscriptionDto[]) => {
            this.loaderFacade.hideLoader(action);
            return MainActions.loadNotSubscribedUsersSuccess({
              notSubscribedUsers,
            });
          }),
          catchError((error) => {
            this.loaderFacade.hideLoader(action);
            return of(
              MainActions.loadNotSubscribedUsersFailure({
                error: error.statusText || error,
              })
            );
          })
        );
      })
    )
  );
  loadNotSubscribedUsersFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.loadNotSubscribedUsersFailure),
        tap((action) => {
          this.toastService.errorFeedback(genericErrorMessage, action.error);
        })
      ),
    { dispatch: false }
  );
  addUserSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.addUserSubscription),
      switchMap((action) => {
        this.loaderFacade.showLoader(action);
        return this.userSubscriptionApiClient
          .addSubscription(
            action.userSubscription.userId || '',
            action.userSubscription.subscriptionType
          )
          .pipe(
            map((userSubscription: UserSubscriptionDto) => {
              this.loaderFacade.hideLoader(action);
              this.facade.loadUsersSubscriptions();
              this.facade.loadNotSubscribedUsers();
              return MainActions.addUserSubscriptionSuccess({
                userSubscription,
              });
            }),
            catchError((error) => {
              this.loaderFacade.hideLoader(action);
              return of(
                MainActions.addUserSubscriptionFailure({
                  error: error.statusText || error,
                })
              );
            })
          );
      })
    )
  );
  addUserSubscriptionFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.addUserSubscriptionFailure),
        tap((action) => {
          this.toastService.errorFeedback(
            'Si è verificato un errore durante la creazione',
            action.error
          );
        })
      ),
    { dispatch: false }
  );
}
