<div
  class="wallet panel secondary flex-grow-1"
  [class.with-border]="showBorder"
  [class.rounded-border]="showBorder"
  *ngIf="wallet"
>
  <div
    class="header d-flex align-items-center"
    [class.simple-view]="simpleView"
  >
    <div class="d-flex align-items-center flex-grow-1">
      <div class="title">
        <div *ngIf="!editing" class="d-flex align-items-center">
          <span>{{ wallet.name }}</span>

          <span
            class="material-symbols-outlined cursor-pointer"
            (click)="editing = !editing"
            *ngIf="canEditName"
          >
            edit
          </span>
        </div>
        <div *ngIf="editing && canEditName" class="d-flex align-items-center">
          <web-input
            class="wallet-name"
            [maxlength]="27"
            [formControl]="nameFormControl"
            ngDefaultControl
            [placeholder]="'Nome portafoglio'"
          ></web-input>
          <span
            class="material-symbols-outlined cursor-pointer"
            (click)="confirmName()"
          >
            check
          </span>
          <span
            class="material-symbols-outlined cursor-pointer"
            (click)="undoName()"
          >
            close
          </span>
        </div>
      </div>
      <div class="time with-space">
        <span *ngIf="!simpleView">creato il </span
        >{{ wallet.dateTimeDescription }}
      </div>
      <div class="vl"></div>
      <div class="month-label with-space">Performance relative a</div>
      <div class="month with-space">
        {{ month | uppercase }}
      </div>

      <div class="ms-auto d-flex align-items-center">
        <button
          class="btn btn-delete-wallet"
          *ngIf="canDeleteWallet"
          (click)="onDelete()"
        >
          <span class="material-symbols-outlined cursor-pointer"> delete </span>
        </button>
      </div>
    </div>
    <web-button
      *ngIf="simpleView && wallet.funds.length > 0"
      [label]="'Vedi dettaglio dei fondi selezionati'"
      class="ms-auto"
      (click)="onShowDetails()"
    ></web-button>
  </div>
  <ng-container>
    <div
      *ngFor="let macroCategory of getWalletMacroCategories()"
      class="macro-category"
    >
      <div
        *ngFor="
          let category of getWalletCategoriesByMC(macroCategory.id);
          index as categoryIndex
        "
      >
        <web-wallet-fund-grid
          [macroCategory]="category.macroCategory"
          [category]="category"
          [funds]="getCategoryFunds(category)"
          [canDelete]="canDeleteFund"
          [showMacroCategory]="categoryIndex === 0"
          (deleted)="onDeleteFund($event)"
        ></web-wallet-fund-grid>
      </div>
    </div>
  </ng-container>

  <!-- <div class="d-flex actions" *ngIf="canDeleteWallet">
    <web-button
      class="ms-auto me-2"
      [label]="'Elimina portafoglio'"
      (click)="onDelete()"
    >
    </web-button>
  </div> -->
</div>
<div class="d-flex mt-4" *ngIf="isModal">
  <button class="btn btn-secondary ms-auto btn-back" (click)="onClose()">
    Torna alla selezione
  </button>
  <web-button
    *ngIf="canCreate"
    class="create"
    (click)="onCreate()"
    [disabled]="!canSave()"
    [label]="'Salva in I tuoi portafogli'"
  >
  </web-button>
</div>
