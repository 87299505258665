import * as moment from 'moment';

export const sort = (
  item1: any,
  item2: any,
  prop: string,
  caseSensitive = false,
  asc = true,
  prop2 = undefined // check two different properties
) => {
  const item2Prop = prop2 || prop;
  let item1Descr = item1[prop];
  let item2Descr = item2[item2Prop];
  if (!caseSensitive) {
    item1Descr = item1[prop].toLowerCase();
    item2Descr = item2[item2Prop].toLowerCase();
  }
  if (asc) {
    return item1Descr === item2Descr ? 0 : item1Descr < item2Descr ? -1 : 1;
  } else {
    return item1Descr === item2Descr ? 0 : item1Descr < item2Descr ? 1 : -1;
  }
};
export const getDate = (dateTime: string, dateFormat = 'YYYY-MM-DD') => {
  return `${moment(dateTime).format(dateFormat)} `;
};

export const getDateTime = (
  dateTime: string,
  dateFormat = 'YYYY-MM-DD',
  timeFormat = 'HH:mm:ss',
  separator = ' '
) => {
  return `${moment(dateTime).format(dateFormat)}${separator}${moment(
    dateTime
  ).format(timeFormat)}`;
};
