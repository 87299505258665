<div
  class="flex-grow-1 d-flex flex-column"
  [class.visually-hidden]="!loggedUser"
>
  <div class="d-flex">
    <span
      class="material-icons-outlined ms-auto cursor-pointer"
      (click)="close()"
    >
      cancel
    </span>
  </div>
  <ng-container *ngIf="loggedUser && loggedUser.subscriptionIsActive">
    <div class="subscription">
      Abbonamento
      {{
        loggedUser.subscription.subscriptionType === 0 ? 'mensile' : 'annuale'
      }}
      sottoscritto il
      {{ getSubscriptionDate(loggedUser.subscription.subscribedAt) }} con
      scadenza il {{ getSubscriptionDate(loggedUser.subscription.expireAt) }}
    </div>
  </ng-container>
  <div class="title">
    Accedi alle aree<br />riservate di
    <span class="primary-inverted">metodics</span><br />potrai avere:
  </div>
  <div>
    <div class="description">
      ✓ Accesso completo e illimitato con dettaglio dei report di tutte le
      categorie dei tuoi fondi diviso per ranking e rating.
    </div>
    <div class="description">
      ✓ Possibilità illimitata di creare il portafoglio azionario ideale in base
      al tuo budget, con dettaglio dei report di tutte le categorie geografiche
      diviso per ranking e rating.
    </div>
    <div class="description">
      ✓ Accesso completo e illimitato con dettaglio dei report delle 8+1
      categorie geografiche divisi per ranking e rating.
    </div>
  </div>
  <div class="primary-inverted description">
    tutte le analisi, i report, i ranking e i rating, vengono ricalcolati al
    termine di ogni mese sulla base dell’andamento del mercato.
  </div>
  <div>
    <web-button
      [styleType]="subscriptionType === 0 ? 'primary' : 'primary-inverted-dark'"
      [label]="'Piano Mensile € 9,90'"
      [withChevron]="true"
      [withChevronAnimation]="true"
      class="action"
      (click)="setSubscriptionPlan(0)"
    ></web-button>
    <div
      [class.d-none]="subscriptionType !== 0"
      id="month-paypal-button-container"
    ></div>
  </div>
  <div>
    <web-button
      [styleType]="subscriptionType === 1 ? 'primary' : 'primary-inverted-dark'"
      [label]="'Piano Annuale € 99,90'"
      [withChevron]="true"
      [withChevronAnimation]="true"
      class="action"
      (click)="setSubscriptionPlan(1)"
    ></web-button>
    <div
      [class.d-none]="subscriptionType !== 1"
      id="year-paypal-button-container"
    ></div>
  </div>
  <div>
    NOTA BENE:<br />
    I piani mensili o annuali NON si rinnovano automaticamente ed è possibile
    disdirli con un <a href="#">semplice click</a>, in qualsiasi momento.
  </div>
</div>
<div [class.visually-hidden]="loggedUser">
  <div>Per poter effettuare l'acquisto è necessario effettuare il login.</div>
  <a class="login-link" (click)="gotoLogin()">Vai alla login</a>
</div>
