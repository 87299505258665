import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiClientConfig } from '../api-client-config';
import { UserDto } from '../dto/user.dto';
import { User } from '../entities/user.entity';
import { BaseApiClient } from './base.api-client';

@Injectable()
export class UserApiClient extends BaseApiClient<UserDto> {
  baseUrl = this.config.apiEndpoint + '/api/user';

  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }

  getCurrentUser() {
    return this.http.get<User>(`${this.baseUrl}/current-user`);
  }
}
