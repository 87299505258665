<ng-container *ngIf="category && funds; else noInfo" class="overflow-x-auto">
  <table class="overflow-x-auto flex-grow-1">
    <thead>
      <tr class="title">
        <td *ngIf="showSelect" colspan="14">
          <div class="d-flex">
            <div class="flex-grow-1 d-flex">
              <span class="description">Report Fondi Metodics</span>
              <span class="categories" *ngIf="macroCategory"
                >MacroCategoria:</span
              >
              <span class="separator"></span>

              <span class="macro-category" *ngIf="macroCategory">{{
                macroCategory.title
              }}</span>
              <span class="separator"></span>
              <span class="categories">Categoria: </span>
              <span class="separator"></span>
              <span class="category"> {{ category.name }}</span>
            </div>
            <div *ngIf="expandible">
              <img
                class="primary chevron"
                [class.up]="expanded"
                [class.down]="!expanded"
                (click)="toggleExpand()"
                src="/assets/images/chevron-right.svg"
              />
            </div>
          </div>
        </td>
        <td *ngIf="!showSelect" colspan="13">
          <div class="d-flex">
            <div class="flex-grow-1 d-flex justify-content-center">
              <span class="description">Report Fondi Metodics</span>
              <span class="macro-category">
                Categoria: {{ category.name }}</span
              >
            </div>
            <div *ngIf="expandible">
              <img
                class="primary chevron"
                [class.up]="expanded"
                [class.down]="!expanded"
                (click)="toggleExpand()"
                src="/assets/images/chevron-right.svg"
              />
            </div>
          </div>
        </td>
      </tr>
      <tr class="subtitle">
        <th colspan="7">
          <div class="d-flex search-box">
            <web-input
              class="search-input"
              [maxlength]="27"
              [formControl]="searchFormControl"
              ngDefaultControl
              [placeholder]="'Cerca fondo per nome'"
              (textValueChange)="searchChanged($event)"
              [textValue]="searchText"
            ></web-input>
            <button
              type="button"
              class="btn btn-sm btn-primary mx-2 border"
              (click)="search()"
            >
              <i class="material-icons add-wallet-icon px-2">search</i>
            </button>
            <button
              type="button"
              class="btn btn-sm btn-primary mx-2 border"
              (click)="cancelSearch()"
            >
              <i class="material-icons add-wallet-icon px-2">cancel</i>
            </button>
          </div>
        </th>
        <th colspan="4" class="three-months col-header">Performance 3 mesi</th>
        <th colspan="4" class="one-year col-header">Performance 12 mesi</th>
      </tr>
      <tr>
        <th *ngIf="showSelect" class="fund-select">Aggiungi a portafoglio</th>
        <th *ngIf="showSelect" class="fund-select">Aggiungi a confronta</th>
        <th class="fund-name">Nome Fondo</th>
        <th class="fund-isin">Isin</th>
        <th class="fund-currency">Valuta</th>
        <th class="fund-ranking">
          <div class="d-flex">
            <span> Ranking Metodics </span>
            <i class="material-icons sort-column">arrow_circle_up</i>
          </div>
        </th>
        <th class="fund-rating">Rating Metodics</th>

        <th class="three-months performance">Performance</th>
        <th class="three-months top-rating">Top rating Metodics</th>
        <th class="three-months category-average">Media della categoria</th>
        <th class="three-months msci-index">Indice MSCI azioni</th>
        <th class="one-year performance">Performance</th>
        <th class="one-year top-rating">Top rating Metodics</th>
        <th class="one-year category-average">Media della categoria</th>
        <th class="one-year msci-index">Indice MSCI azioni</th>
      </tr>
    </thead>

    <ng-container *ngIf="expanded">
      <tbody>
        <tr
          *ngFor="let fund of filteredFunds; index as fundIndex"
          [class.even]="fundIndex % 2 === 0"
          [class.cursor-pointer]="!showSelect"
          [class.selected]="fund.selected"
          (click)="onClick(fund)"
        >
          <td *ngIf="showSelect" class="fund-select">
            <button
              type="button"
              class="btn btn-sm btn-primary p-1 border"
              (click)="addFundToWallet(fund)"
            >
              <i class="material-icons add-wallet-icon">add</i>
            </button>
          </td>
          <td *ngIf="showSelect" class="fund-select">
            <button
              type="button"
              class="btn btn-sm btn-primary p-1 border"
              (click)="addFundToCompare(fund)"
            >
              <i class="material-icons add-wallet-icon">playlist_add</i>
            </button>
          </td>
          <td class="fund-name">
            {{ fund.name }}
          </td>
          <td class="fund-isin">
            {{ fund.isin }}
          </td>
          <td class="fund-currency">
            {{ fund.currency }}
          </td>
          <td class="fund-ranking">{{ fund.lastRanking }}</td>
          <td class="fund-rating">
            <!-- <ngb-rating
              [max]="+(fund.lastRating || '')"
              [rate]="+(fund.lastRating || '')"
              [readonly]="true"
            ></ngb-rating> -->
            <div class="me-1 text-center" *ngIf="+fund.lastRating > 0">
              <i class="material-icons star">star_fill</i>
              <i class="material-icons star" *ngIf="+fund.lastRating > 1"
                >star_fill</i
              >
              <i class="material-icons star" *ngIf="+fund.lastRating > 2"
                >star_fill</i
              >
              <i class="material-icons star" *ngIf="+fund.lastRating > 3"
                >star_fill</i
              >
              <i class="material-icons star" *ngIf="+fund.lastRating > 4"
                >star_fill</i
              >
            </div>
          </td>
          <td class="three-months performance">
            {{
              fund.last3MonthsPerformanceAverage * 100
                | number : '1.2-2' : 'it-IT'
            }}
            %
          </td>
          <td class="three-months top-rating">
            {{
              (category.last3MonthsTopRatingFundPerformanceAverage ?? 0) * 100
                | number : '1.2-2' : 'it-IT'
            }}
            %
          </td>
          <td class="three-months category-average">
            {{
              (category.last3MonthsPerformanceAverage ?? 0) * 100
                | number : '1.2-2' : 'it-IT'
            }}
            %
          </td>
          <td class="three-months msci-index">
            {{
              (category.last3MonthsMSCIIndex ?? 0) * 100
                | number : '1.2-2' : 'it-IT'
            }}
            %
          </td>
          <td class="one-year performance">
            {{
              (fund?.last12MonthsPerformanceAverage ?? 0) * 100
                | number : '1.2-2' : 'it-IT'
            }}
            %
          </td>
          <td class="one-year top-rating">
            {{
              (category.last12MonthsTopRatingFundPerformanceAverage ?? 0) * 100
                | number : '1.2-2' : 'it-IT'
            }}
            %
          </td>
          <td class="one-year category-average">
            {{
              (category.last12MonthsPerformanceAverage ?? 0) * 100
                | number : '1.2-2' : 'it-IT'
            }}
            %
          </td>
          <td class="one-year msci-index">
            {{
              (category.last12MonthsMSCIIndex ?? 0) * 100
                | number : '1.2-2' : 'it-IT'
            }}
            %
          </td>

          <!-- <td class="fund-rating">
        <ngb-rating
          *ngIf="fund.rating !== '***'; else elseBlock"
          [max]="+fund.rating"
          [rate]="+fund.rating"
          [readonly]="true"
          class="rate"
        ></ngb-rating>
        <ng-template #elseBlock>{{ fund.rating }}</ng-template>
      </td> -->
        </tr>
      </tbody>
    </ng-container>
  </table>
</ng-container>
<ng-template #noInfo>Nessun fondo</ng-template>
