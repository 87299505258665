import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiClientConfig } from '../api-client-config';
import { CategoryDto } from '../dto/category.dto';
import { BaseReadApiClient } from './base-read.api-client';

@Injectable()
export class CategoryApiClient extends BaseReadApiClient<CategoryDto> {
  baseUrl = this.config.apiEndpoint + '/api/category';

  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }
  getByMacroCategory(macroCategoryId: string) {
    return this.http.get<CategoryDto[]>(
      `${this.baseUrl}/macro-category/${macroCategoryId}`
    );
  }
}
