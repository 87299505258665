<footer>
  <div class="container-xxl">
    <div class="row">
      <div class="col">
        <div class="row">
          <!-- <div class="col-4">
            <div class="area">metodics</div>
            <div>Chi siamo</div>
            <div>News</div>
            <div>Servizi</div>
            <div>Contattaci</div>
          </div> -->
          <div class="col-4">
            <div class="area">Area legale</div>
            <div class="cursor-pointer footer-link" [routerLink]="['/']">
              Condizioni generali di vendita
            </div>
            <div class="cursor-pointer footer-link" [routerLink]="['/']">
              Privacy policy
            </div>
          </div>
          <div class="col-4">
            <div class="area">Area Personale</div>
            <!-- <div class="cursor-pointer" [routerLink]="['/debug']">Debug</div> -->
            <div
              class="cursor-pointer footer-link"
              [routerLink]="['/miei-dati']"
            >
              I miei dati
            </div>
            <div
              class="cursor-pointer footer-link"
              [routerLink]="['/miei-portafogli']"
            >
              I miei portafogli
            </div>
            <div
              class="cursor-pointer footer-link"
              [routerLink]="['/miei-dispositivi']"
            >
              I miei dispositivi
            </div>
          </div>
          <div class="col-4" *ngIf="(loggedUser$ | async)?.isAdmin">
            <div class="area">Admin</div>
            <div
              class="cursor-pointer footer-link"
              [routerLink]="['/gestione-abbonamento']"
            >
              Gestione abbonamenti
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-4">
        <div>Sede in Via .....</div>
        <div>Metodi di pagamento</div>
      </div> -->
    </div>
    <div class="owner">
      "Metodics" e il logo "Metodics" sono di proprietà di Metodics S.r.l.
      Copyright 2022 Metodics S.r.l. Tutti i diritti riservati
    </div>
    <div>Version 1.0.15</div>
  </div>
</footer>
