import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiClientConfig } from '../api-client-config';
import { BaseApiClient } from './base.api-client';

@Injectable()
export abstract class BaseReadApiClient<T> extends BaseApiClient<T> {
  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }

  getAll() {
    return this.http.get<T[]>(this.baseUrl);
  }

  get(code: string) {
    return this.http.get<T>(`${this.baseUrl}/${code}`);
  }
}
