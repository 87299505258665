import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Wallet } from '@metodics/api-client';

@Component({
  selector: 'web-wallet-selector',
  templateUrl: './wallet-selector.component.html',
  styleUrls: ['./wallet-selector.component.scss'],
})
export class WalletSelectorComponent {
  @Input()
  wallets?: Wallet[] = undefined;

  @Output()
  selected = new EventEmitter<string>();

  @Output()
  closePopup = new EventEmitter();

  select(walletId: string) {
    if (this.selected) {
      this.selected.emit(walletId);
    }
  }
  onClose() {
    if (this.closePopup) {
      this.closePopup.emit();
    }
  }
}
