import { Component, Input } from '@angular/core';

@Component({
  selector: 'web-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent {
  @Input()
  backgroundColor = 'white';
  @Input()
  color = 'black';
  @Input()
  backgroundImage?: string = undefined;
  @Input()
  type: 'primary' | 'secondary' | 'default' = 'default';
  @Input()
  borderRadius = true;

  @Input()
  title = '';
  @Input()
  subtitle = '';
}
