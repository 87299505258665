<div
  class="panel-container"
  [style.backgroundColor]="backgroundColor"
  [style.color]="color"
  [ngClass]="{
    primary: type === 'primary',
    secondary: type === 'secondary',
    default: type === 'default',
    'border-radius': borderRadius
  }"
  [ngStyle]="{
    backgroundColor: type === 'default' ? backgroundColor : '',
    color: type === 'default' ? color : '',
    'background-image': backgroundImage ? 'url(' + backgroundImage + ')' : ''
  }"
>
  <div class="header">
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="body">
    <ng-content select="[body]"></ng-content>
  </div>
  <ng-content></ng-content>
</div>
